import { Button } from "@mui/material";

export const getColumns = (onUpdate) => [
  {
    field: "isTopRatedLister",
    headerName: "Is Top Rated Lister",
    flex: 1,
  },
  {
    field: "name",
    headerName: "Name",
    flex: 1,
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
  },
  {
    field: "actions",
    headerName: "Actions",
    flex: 1,
    renderCell: (params) => {
      return (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            onUpdate(params.row);
          }}
          variant="contained"
        >
          Update
        </Button>
      );
    },
  },
];
