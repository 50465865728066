import { Container, Grid } from "@mui/material";
import React from "react";
import LoginScreen from "../../screens/auth/login";
import { useStyles } from "../../../style";
const AuthLayoutComponent = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth='sm'>
        <Grid container direction='column' justify='center' spacing={2}>
          <LoginScreen />
        </Grid>
      </Container>
    </div>
  );
};

export default AuthLayoutComponent;
