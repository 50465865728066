import { React, useRef, useState } from 'react';
import { Box } from '@mui/material';
import TypoGraphyComponent from '../../atoms/typography';
import { useStyles } from './styles';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { Button } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { isEmpty } from 'lodash';
import { DEFAULT_ID } from '../../../constants/carouselConstants';
import InputField from '../../atoms/input';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';

const CarouselItem = ({
  data,
  position,
  onFileSelect,
  onFileUpload,
  onFileDelete,
  onLinkAddressChange,
  ...otherProps
}) => {
  const classes = useStyles();

  const fileInputRef = useRef();
  const [linkAddress, setLinkAddress] = useState(data?.webAddress || '');
  const [showLinkField, setLinkField] = useState(false);

  const handleFileSelect = (event) => {
    if (fileInputRef?.current?.files?.length > 0) {
      let fileObject = fileInputRef.current.files[0];
      const assetFileUrl = window.URL.createObjectURL(fileObject);

      let objectData = {
        ...data,
        name: fileObject.name,
        url: assetFileUrl,
        type: fileObject.type,
        position,
        file: fileObject,
        webAddress: '',
      };

      // assign default id to new item because upload api expects id
      if (isEmpty(data)) {
        objectData['id'] = DEFAULT_ID;
      }
      onFileSelect(objectData);
    }
  };

  const handleFileDelete = () => {
    setLinkAddress(''); // so that link address is removed as well
    onFileDelete(data);
  };

  const toggleLinkField = () => {
    setLinkField(!showLinkField);
  };

  const getCardComponent = () => {
    let cardComponent = 'div';
    if (data?.type.startsWith('image/')) cardComponent = 'img';
    else if (data?.type.startsWith('video/')) cardComponent = 'video';
    return cardComponent;
  };

  function openInNewTab() {
    if (data?.url) window.open(data.url, '_blank').focus();
  }

  return (
    <Box className={classes.carouselItem}>
      <TypoGraphyComponent
        text={`Asset ${position + 1}`}
        variant="h5"
        color="appGray"
        className={classes.assetTitleText}
      />
      <Box className={classes.carouselContent}>
        {Object.keys(data).length > 0 ? (
          <>
            <Box className={classes.carouselItemLeft}>
              <Card className={classes.mediaComponent} onClick={openInNewTab}>
                <CardMedia
                  component={getCardComponent()}
                  src={data.url}
                  alt={data.name}
                  sx={{ maxWidth: 80, width: 80, height: 80 }}
                />
              </Card>
              <Box className={classes.fileNameContainer}>
                <TypoGraphyComponent
                  text={data.name}
                  variant="h6"
                  color="appGray"
                  onClick={openInNewTab}
                />
              </Box>
            </Box>
            <Box className={classes.carouselItemFreeSpace} />
            <Box className={classes.carouselItemRight}>
              <IconButton aria-label="delete" onClick={handleFileDelete}>
                <DeleteOutlineOutlinedIcon
                  fontSize="large"
                  color="appGray"
                  className={classes.iconButton}
                />
              </IconButton>
              <IconButton aria-label="insert link" onClick={toggleLinkField}>
                <InsertLinkIcon
                  fontSize="large"
                  color="appGray"
                  className={classes.iconButton}
                />
              </IconButton>
              <Box className={classes.uploadButton}>
                <Button
                  variant="contained"
                  color="appLightGray"
                  onClick={() => fileInputRef.current.click()}
                >
                  Change
                  <input
                    ref={fileInputRef}
                    type="file"
                    hidden
                    accept="image/*, video/*"
                    onChange={handleFileSelect}
                  />
                </Button>
              </Box>
            </Box>
          </>
        ) : (
          <Box className={classes.browseButtonContainer}>
            <Box className={classes.uploadButton}>
              <Button
                variant="contained"
                color="appLightGray"
                onClick={() => fileInputRef.current.click()}
              >
                Browse File
                <input
                  ref={fileInputRef}
                  type="file"
                  hidden
                  accept="image/*, video/*"
                  onChange={handleFileSelect}
                />
              </Button>
            </Box>
          </Box>
        )}
      </Box>
      {showLinkField ? (
        <Box className={classes.linkContainer}>
          <Box className={classes.linkFieldContainer}>
            <InputField
              size="small"
              placeholder="Url"
              name="username"
              variant="outlined"
              label="Url"
              autoFocus
              inputProps={{
                autoComplete: 'off', // to turn off autocomplete
              }}
              value={linkAddress}
              onChange={(e) => setLinkAddress(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setLinkAddress('')} edge="end">
                      {<CloseIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box className={classes.uploadButton}>
            <Button
              variant="contained"
              color="appLightGray"
              onClick={() => {
                if (linkAddress !== data.webAddress) {
                  onLinkAddressChange(linkAddress, position);
                }
                toggleLinkField();
              }}
            >
              {linkAddress !== data.webAddress ? 'Save' : 'Close'}
            </Button>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default CarouselItem;
