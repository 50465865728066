import React, { useState, useEffect } from "react";
import {
  ListItem,
  Button,
  ListItemText,
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import TypoGraphyComponent from "../../atoms/typography";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { endpoints } from "../../../config/apiConfig";
import logService from "../../../services/logService";
import { useStyles } from "./styles";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationDialog from "../../ConfirmationDialog";
import { PAGE_LIMIT as limit } from "../../../constants/listingConstants";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ButtonComponent from "../../atoms/button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import DriveFileMoveOutlinedIcon from "@mui/icons-material/DriveFileMoveOutlined";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ListingScreen = () => {
  const classes = useStyles();
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showMultiReorderDialog, setShowMultiReorderDialog] = useState(false);
  const [selectedListing, setselectedListing] = useState();
  const [isLoadingMore, setLoadingMore] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [positionType, setPositionType] = useState("");
  const [positionText, setPositionText] = useState("");
  const [position, setPosition] = useState(1);
  const [collections, setCollections] = useState([]);
  const [selectedCollections, setSelectedCollections] = useState([]);
  // sometime we need to fetch fresh data from api but the useEffect does not work
  // because current page is already the same as we are setting it to
  // so we'll use this state as a hack to triggure useEffect for refresh
  // please do not use it if you don't understand it
  const [forceUpdateData, setForceUpdateData] = useState(false);
  const [filters, setFilters] = useState([
    {
      productsize: "Product Size",
      productcolor: "Product Color",
      collection: "Collection",
      listername: "Lister Name",
      listeremail: "Lister Email",
      productid: "Product Id",
      featuredlister: "Featured Lister",
    },
  ]);

  const accessToken = localStorage.getItem("_token");
  const fetchCollections = async () => {
    const response = await axios.get(endpoints.collections.getCollections, {
      headers: {
        "Content-Type": "application/json-patch+json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (response.data.success) {
      setCollections(response.data.data);
    }
  };
  const insertProductsIntoCollections = async () => {
    const response = await axios.post(
      endpoints.collections.CollectionsProducts,
      {
        collectionsIds: selectedCollections.map((value) => value.id),
        productIds: selectionModel,
      },
      {
        headers: {
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response.data.success) {
      toast.success("Products added to Collections");
      setSelectedCollections([]);
      setSelectionModel([]);
    }
  };

  useEffect(() => {
    fetchCollections();
  }, []);

  useEffect(() => {
    if (currentPage === 1) fetchProducts();
    else fetchMoreProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, forceUpdateData]);

  const fetchProducts = async () => {
    setLoading(true);
    await fetchDataFromAPI();
    setLoading(false);
  };

  const fetchMoreProducts = async () => {
    setLoadingMore(true);
    await fetchDataFromAPI();
    setLoadingMore(false);
  };

  const fetchDataFromAPI = async () => {
    try {
      const response = await axios.get(`${endpoints.products.getProductsForDashboard}`, {
        params: { limit: limit, current: currentPage, SortBy: "Position" },
      });
      const { hasMore } = response.data.data;
      setHasMore(hasMore);
      const { data } = response.data.data;
      // if first page is set means overwrite the data
      // else add it (pagination)
      if (currentPage === 1) {
        setProducts(data);
      } else {
        setProducts((prev) => [...prev, ...data]);
      }
      return true;
    } catch (error) {
      logService.logError(
        `file: listings> index.js, function: fetchDataFromAPI, message: ${error.message}`
      );
      return false;
    }
  };

  const handleDragEnd = (result) => {
    const { destination } = result;
    const { source } = result;

    if (!destination) return;
    if (source.index === destination.index) return;

    const originalProducts = products;
    reorderItemsInState(products, source.index, destination.index);
    reorderItemsInDb(destination.index + 1, result.draggableId)
      .then((res) => {
        const { data } = res;
        if (data && data.success) {
          toast.success("Successfully reordered!");
        }
      })
      .catch((error) => {
        setProducts(originalProducts);
        toast.error("Something went wrong.");
        logService.logError(
          `file: listings> index.js, function: handleDragEnd, message: ${error.message}`
        );
      });
  };

  function reorderItemsInState(products, sourceIndex, destinationIndex) {
    const items = Array.from(products);
    const [reorderedItem] = items.splice(sourceIndex, 1);
    items.splice(destinationIndex, 0, reorderedItem);
    setProducts(items);
  }

  const reorderItemsInDb = async (itemIndex, itemID) => {
    return await axios.put(endpoints.products.updateProductsPosition, {
      ProductId: itemID,
      Position: itemIndex,
    });
  };

  const handleDeleteClick = (item) => {
    setselectedListing(item);
    setShowDeleteDialog(true);
  };

  const deleteSelectedReservations = async () => {
    try {
      setShowDeleteDialog(false);
      setLoading(true);
      const res = await axios.delete(`${endpoints.products.product}/${selectedListing.id}`);
      const { data } = res;
      if (data && data.success) {
        toast.success(
          `Product ${selectedListing.title} and its related data is successfully deleted.`,
          { duration: 4000 }
        );
        const prods = products.filter((m) => m.id !== selectedListing.id);
        setProducts(prods);
      } else {
        toast.error("Something went wrong while deleting the product.");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong while deleting the product.");
      logService.logError(
        `file: listings> index.js, function: handleProductDelete, message: ${error.message}`
      );
    }
  };

  const handleMultiReOrder = async () => {
    setShowMultiReorderDialog(false);
    setLoading(true);
    let payload = {
      productIds: selectionModel,
      type: positionType.toLowerCase(),
      position,
    };

    const response = await axios.post(endpoints.products.rearrangeProductPositions, { ...payload });

    if (response.status === 200 && response.data.success) {
      // reset position state

      setSelectionModel([]);
      setPositionType("");
      setPositionText("");
      setPosition(1);

      // fetch fresh data (refresh)
      // don't use the force update unless you understand it
      if (currentPage === 1) setForceUpdateData(!forceUpdateData);
      else {
        setCurrentPage(1);
      }
    }
  };

  const loader = (
    <Box className={classes.loderContainer}>
      <CircularProgress />
    </Box>
  );

  const loadMore = (
    <Box className={classes.loadMoreContainer}>
      <Button
        disableRipple
        onClick={async () => {
          setCurrentPage(currentPage + 1);
        }}
        variant="text"
        color="info"
        style={{ backgroundColor: "transparent", paddingRight: 10 }}
        disabled={isLoadingMore}
      >
        Load more products
      </Button>
      <Box className={classes.loadMoreCircularProgressContainer}>
        {isLoadingMore ? <CircularProgress size={20} color="info" /> : null}
      </Box>
    </Box>
  );

  const handleSelectionChange = (id) => {
    const currentSelection = [...selectionModel];
    if (!currentSelection.includes(id)) {
      currentSelection.push(id);
    } else {
      const itemIndex = currentSelection.indexOf(id);
      currentSelection.splice(itemIndex, 1);
    }
    setSelectionModel(currentSelection);

    if (currentSelection.length <= 0) {
      setPositionType("");
      setPositionText("");
      setPosition(1);
    }
  };

  const handleSelectCollections = (event, value) => {
    setSelectedCollections(value);
  };

  return (
    <Grid container spacing={0} direction="column" className={classes.container}>
      <Toaster
        toastOptions={{
          className: "",
          style: {
            color: "#FFB3C3",
            backgroundColor: "rgba(40, 0, 0, 0.87)",
            fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
          },
        }}
      />
      {showDeleteDialog && (
        <ConfirmationDialog
          open={showDeleteDialog}
          title="Manual Actions Required!"
          message={[
            "You are about to “hard” delete a Listing. This will delete the Listing from Shopify, delete all images and Reservations, and remove the Listing from Search.",
            "You will need to do the following manually:",
            "- Inform the Lister and any affected Renters.",
            "- Refund any Renters.",
            "Are you sure you want to continue?",
          ]}
          acceptTitle="DELETE"
          onAccept={deleteSelectedReservations}
          rejectTitle="CANCEL"
          onReject={() => setShowDeleteDialog(false)}
        />
      )}
      {showMultiReorderDialog && (
        <ConfirmationDialog
          open={showMultiReorderDialog}
          title="Please Note that!"
          message={[
            "You are about to reorder these products, please note that:",
            "- It is an irreversible operation and once done you won't be able to undo it.",
            "- It might take some time as it is a heavy operation.",
            "Are you sure you want to continue?",
          ]}
          acceptTitle="ReOrder"
          onAccept={handleMultiReOrder}
          rejectTitle="CANCEL"
          onReject={() => setShowMultiReorderDialog(false)}
        />
      )}
      <Box className={classes.header}>
        <TypoGraphyComponent text="Listings" variant="h5" color="primary" />

        <Box className={classes.headerOptions}>
          <Box className={classes.headerOptionsLeft}>
            {selectionModel.length > 0 && !isLoading ? (
              <>
                <Autocomplete
                  size="small"
                  value={positionType}
                  onChange={(event, newValue) => {
                    setPositionType(newValue);
                  }}
                  inputValue={positionText}
                  onInputChange={(event, newInputValue) => {
                    setPositionText(newInputValue);
                  }}
                  disableClearable
                  id="positionType"
                  options={["Top", "Bottom", "Position"]}
                  sx={{ width: 180 }}
                  renderInput={(params) => <TextField {...params} placeholder="Move to" />}
                />

                {positionType === "Position" ? (
                  <Box className={classes.positionInputContainer}>
                    <TextField
                      size="small"
                      id="position Number"
                      type="number"
                      classes={{}}
                      sx={{ width: 120 }}
                      value={position}
                      onChange={(event) => {
                        setPosition(event.target.value);
                      }}
                      InputProps={{ inputProps: { min: 1 } }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Box>
                ) : null}

                <Box className={classes.moveButtonContainer}>
                  <ButtonComponent
                    style={{ padding: 0, marginLeft: 10 }}
                    variant="text"
                    color="primary"
                    text="Move"
                    onClick={async () => {
                      // await handleMultiReOrder();
                      setShowMultiReorderDialog(true);
                    }}
                    align="center"
                    startIcon={<DriveFileMoveOutlinedIcon />}
                    disabled={positionType === "" || position === ""}
                  />
                </Box>
              </>
            ) : null}
          </Box>
          <Box className={classes.headerFreeSpace}>
            {/* <Box className={classes.moveButtonContainer}></Box> */}
          </Box>
          <Box className={classes.headerOptionsRight}>
            {selectionModel.length > 0 && (
              <>
                <ButtonComponent
                  style={{ padding: 0, marginLeft: 10 }}
                  variant="text"
                  color="primary"
                  text="Insert"
                  onClick={insertProductsIntoCollections}
                  align="center"
                  startIcon={<DriveFileMoveOutlinedIcon />}
                />

                <Autocomplete
                  multiple
                  size="small"
                  id="checkboxes-tags-demo"
                  options={collections}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.title}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox style={{ marginRight: 8 }} checked={selected} />
                      {option.title}
                    </li>
                  )}
                  style={{ width: 500 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Collections"
                      placeholder="Select Collections"
                    />
                  )}
                  value={selectedCollections && selectedCollections}
                  onChange={handleSelectCollections}
                />
              </>
            )}
          </Box>
        </Box>
      </Box>
      <div className="filtersWrapper">
        <FormControl>
          <InputLabel id="demo-simple-select-label">Product Size</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filters.productsize}
            label="productsize"
            // onChange={(e) => setProductTag(e.target.value)}
          >
            <MenuItem value="color">Color</MenuItem>
            <MenuItem value="size">Size</MenuItem>
            <MenuItem value="price">Rental price</MenuItem>
            <MenuItem value="category">Catagory</MenuItem>
          </Select>
        </FormControl>

        <FormControl>
          <InputLabel id="demo-simple-select-label">Product Color</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filters.productcolor}
            label="productcolor"
            // onChange={(e) => setIsEqual(e.target.value)}
          >
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </FormControl>

        <FormControl>
          <InputLabel id="demo-simple-select-label">Collections</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filters.collection}
            label="collections"
            // onChange={(e) => setIsEqual(e.target.value)}
          >
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </FormControl>

        <FormControl>
          <InputLabel id="demo-simple-select-label">Lister Name</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filters.listername}
            label="listername"
            // onChange={(e) => setIsEqual(e.target.value)}
          >
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </FormControl>

        <FormControl>
          <InputLabel id="demo-simple-select-label">Lister Email</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filters.listeremail}
            label="listeremail"
            // onChange={(e) => setIsEqual(e.target.value)}
          >
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </FormControl>

        <FormControl>
          <InputLabel id="demo-simple-select-label">Product Id</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filters.productid}
            label="productid"
            // onChange={(e) => setIsEqual(e.target.value)}
          >
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </FormControl>

        <FormControl>
          <InputLabel id="demo-simple-select-label">Featured Lister</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filters.featuredlister}
            label="featuredlister"
            // onChange={(e) => setIsEqual(e.target.value)}
          >
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </FormControl>
      </div>
      <Box className={classes.tableContainer}>
        {isLoading ? (
          loader
        ) : (
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="list">
              {(provided) => (
                <Box {...provided.droppableProps} ref={provided.innerRef}>
                  {products &&
                    products.map((item, index) => {
                      return (
                        <DraggableComponent
                          key={item.id}
                          item={item}
                          index={index}
                          checked={selectionModel.includes(item.id)}
                          onSelectionChange={handleSelectionChange}
                          onDelete={handleDeleteClick}
                        />
                      );
                    })}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
        )}
        {!isLoading && hasMore && loadMore}
      </Box>
    </Grid>
  );
};

const DraggableComponent = ({ item, index, checked, onSelectionChange, onDelete }) => {
  const classes = useStyles();
  return (
    <div className="tableGrid">
      <Draggable draggableId={item.id} index={index}>
        {(provided) => (
          <ListItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            secondaryAction={
              <IconButton edge="end" aria-label="delete">
                <DragHandleIcon {...provided.dragHandleProps} />
              </IconButton>
            }
          >
            <FormControlLabel
              className={classes.indexLabel}
              control={<Checkbox checked={checked} onChange={() => onSelectionChange(item.id)} />}
              label={index + 1}
            />
            <ListItemAvatar>
              <Avatar src={item.imgSrc} variant="square"></Avatar>
            </ListItemAvatar>
            <ListItemText primary={item.title} />
            <IconButton edge="end" aria-label="delete" onClick={() => onDelete(item)}>
              <DeleteIcon />
            </IconButton>
          </ListItem>
        )}
      </Draggable>
    </div>
  );
};

export default ListingScreen;
