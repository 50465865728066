import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import DeleteIcon from "@mui/icons-material/Delete";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import {
  Table,
  TableBody,
  Paper,
  TableContainer,
  Box,
  TableHead,
  TableRow,
  TableCell,
  Button,
} from "@mui/material";
import { endpoints } from "../../../config/apiConfig";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

function CollectionsGrid(props) {
  const [isImage, setIsImage] = useState(false);
  const [collections, setCollections] = useState([]);
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("_token");

  const fetchCollections = async () => {
    const response = await axios.get(endpoints.collections.getCollections, {
      headers: {
        "Content-Type": "application/json-patch+json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.data.success) {
      setCollections(response.data.data);
    }
  };

  useEffect(() => {
    fetchCollections();
  }, []);

  const handleEdit = (id) => {
    navigate(`/edit-collection/${id}`);
  };

  const handleDeleteCollection = async (id) => {
    if (window.confirm("Are you sure you want to remove the collection?")) {
      let result = await axios.delete(
        `${endpoints.collections.getCollections}?collectionId=${id}`,
        {
          headers: {
            "Content-Type": "application/json-patch+json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (result.data.success) {
        toast.success("Collection Deleted");
        fetchCollections();
      }
    }
  };

  return (
    <div>
      <Toaster
        toastOptions={{
          className: "",
          style: {
            color: "#FFB3C3",
            backgroundColor: "rgba(40, 0, 0, 0.87)",
            fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
          },
        }}
      />
      <TableContainer component={Paper} sx={{ height: "60vh", overflow: "auto" }}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell>&nbsp;</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Products</TableCell>
              <TableCell>Collection Cover Image</TableCell>

              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {collections.length > 0
              ? collections.map((item) => {
                  return (
                    <>
                      <TableRow className="collectionRow">
                        <TableCell>
                          <Checkbox {...label} />
                        </TableCell>
                        <TableCell>{item.title}</TableCell>
                        <TableCell>00</TableCell>
                        <TableCell className="categoryConditions">
                          <Avatar alt="Remy Sharp" src={item.coverImageSrc} />
                        </TableCell>
                        <TableCell>
                          <span className="collectionsAction">
                            <Button
                              onClick={() => handleEdit(item.id)}
                              color="primary"
                              variant="contained"
                            >
                              Change
                            </Button>
                            <InsertLinkIcon
                              className="linkIcon"
                              onClick={() => setIsImage(!isImage)}
                            />
                            <DeleteIcon onClick={() => handleDeleteCollection(item.id)} />
                          </span>
                        </TableCell>
                        {isImage && (
                          <TableRow className="updateImage">
                            <input type="text" />
                          </TableRow>
                        )}
                      </TableRow>
                    </>
                  );
                })
              : "No Collections"}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default CollectionsGrid;
