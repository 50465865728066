import { Chip } from "@mui/material";
import React from "react";
import { theme } from "../../../theme";

const ChipComponent = (props) => {
  const { size, label, color, style } = props;
  return (
    <>
      <Chip size={size} color={color} label={label} style={style} />
    </>
  );
};

export default ChipComponent;
