//packages block
import { useEffect, useState } from "react";
import moment from "moment/moment";
import axios from 'axios';
import * as yup from "yup";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, Grid, Autocomplete, TextField } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
//components block
import CustomController from "../../atoms/input/CustomController";
import CustomSelect from "../../atoms/input/CustomSelect";
//others block
import { formatDate } from "../../screens/coupon";
import { endpoints } from "../../../config/apiConfig";
import Alert from "../../atoms/alert";

const today = new Date();
today.setHours(0, 0, 0, 0)

const couponValidationSchema = (startDate) => yup.object({
  name: yup.string().required("This field is required."),
  code: yup.string().required("This field is required."),
  category: yup.string().required("This field is required."),
  type: yup.string().required("This field is required."),
  discountValue: yup.string().required("This field is required"),
  startDate:
    startDate && moment(startDate).isBefore(moment()) ?
      yup.date().required()
      : yup.date().default(null)
        .min(today, 'Please choose future date')
        .nullable()
        .notRequired(),
  endDate: yup.date().default(null).when("startDate", {
    is: (val) => !!val,
    then: yup.date().min(yup.ref('startDate'), "Please choose future date")
      .test(
        "same_dates_test",
        "Start and end dates must not be equal.",
        function (value) {
          const { startDate } = this.parent;
          return value.getTime() !== startDate.getTime();
        }
      ).required("This field is required."),
    otherwise: yup.date()
      .nullable()
      .notRequired()
  }),

  categoryVariants: yup.array().of(
    yup.object().shape({
      id: yup.string().required(),
      value: yup.string().required()
    })
  )
})

const SectionModal = ({ open, setOpen, setCoupons, selectedCoupon, setSelectedCoupon, productCategories, coupons }) => {
  const [loading, setLoading] = useState(false);
  const { id = "", name = "", code = "", category = "1", type = "1", discountValue = "", startDate, endDate, categoryVariants = [], isNewCustomer = false } = selectedCoupon || {};

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(couponValidationSchema(startDate)),
    defaultValues: {
      name,
      code,
      category,
      type,
      discountValue,
      startDate: startDate ? formatDate(moment(startDate).local()) : null,
      endDate: endDate ? formatDate(moment(endDate).local()) : null,
      categoryVariants: [],
      isNewCustomer: false,
    }
  });

  const handleClose = () => {
    setOpen(false)
  }

  const { handleSubmit, reset, setValue, control } = methods;
  const disableStartDate = startDate ? moment(startDate).isBefore(moment()) : false;
  // ,  moment(startDate,"YYYY/MM/DD")
  const onSubmit = async (payload) => {
    const { startDate, endDate, category, type, name, code, categoryVariants, isNewCustomer } = payload;
    const startDateUtc = moment.utc(startDate);
    const endDateUtc = moment.utc(endDate);
    const categoryVariantIds = categoryVariants?.map((item) => item.id)
    const requestData = {
      ...payload, name: name.trim().toLowerCase(),
      code: code.trim().toLowerCase(),
      category: parseInt(category),
      type: parseInt(type),
      startDate: startDateUtc,
      endDate: endDateUtc,
      categoryVariants: categoryVariantIds,
      isNewCustomer
    }

    const header = {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("_token")}`
      }
    }
    try {
      setLoading(true)

      if (selectedCoupon) {
        const updatedCoupons = [];
        const { data: { data, message } } = await axios.put(endpoints.coupons.createOrUpdateCoupon, { ...requestData, id }, header);
        for (let coupon of coupons) {
          const { id } = coupon
          if (parseInt(id) === parseInt(selectedCoupon?.id)) {
            updatedCoupons.push(data)
          } else {
            updatedCoupons.push(coupon);
          }
        }

        setCoupons(updatedCoupons)
        Alert.success(message)
      }
      else {
        //create
        const { data: { data, message } } = await axios.post(endpoints.coupons.createOrUpdateCoupon, requestData, header);
        setCoupons((coupons) => [data, ...coupons])
        Alert.success(message)
      }

      setLoading(false)
      setSelectedCoupon(null)
      handleClose()
    } catch (error) {
      const { response: { status, data } } = error
      if (status && parseInt(status) === 400) {
        Alert.error(data.message)
      }

      setLoading(false)
    }
  }

  useEffect(() => {
    reset()
    if (!open) {
      setSelectedCoupon(null)
    }
  }, [open, reset, setSelectedCoupon])

  useEffect(() => {
    name && setValue("name", name);
    isNewCustomer && setValue("isNewCustomer", isNewCustomer);
    code && setValue("code", code);
    startDate && setValue("startDate", formatDate(moment(startDate).local()));
    endDate && setValue("endDate", formatDate(moment(endDate).local()));
    discountValue && setValue("discountValue", discountValue)
    category && setValue("category", category);
    type && setValue("type", type);

    if (categoryVariants?.length) {
      const selectedCategories = productCategories.filter((item) => (!!categoryVariants.includes(parseInt(item.id))))
      setValue("categoryVariants", selectedCategories)
    }

  }, [name, code, setValue, startDate, endDate, discountValue, category, type, categoryVariants, productCategories, isNewCustomer]);

  return (
    <FormProvider {...methods}>
      <Dialog open={open} onClose={() => { }} maxWidth="sm" fullWidth>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="alert-dialog-title">{selectedCoupon ? "Update" : "Create"} Discount Code</DialogTitle>

          <DialogContent>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <CustomController
                  controllerName="name"
                  controllerLabel="Name"
                  fieldType="text"
                  key={`name-controllerLabel`}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <CustomController
                  controllerName="code"
                  controllerLabel="Code"
                  fieldType="text"
                  disabled={!!selectedCoupon}
                  key={`code-controllerLabel`}
                />
              </Grid>

              <Grid item sm={12} xs={12}>
                <Controller
                  name="categoryVariants"
                  control={control}
                  render={({ field, fieldState: { error: { message } = {} } }) => {
                    return (
                      <Autocomplete
                        {...field}
                        multiple
                        id="Product-Categories"
                        options={productCategories}
                        getOptionLabel={(option) => option.value}
                        value={field.value}
                        filterSelectedOptions
                        onChange={(event, value) => {
                          field.onChange(value)
                        }}
                        isOptionEqualToValue={(option, value) =>
                          value === undefined || value === "" || parseInt(option.id) === parseInt(value.id)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Product Categories"
                            placeholder="Product Categories"
                            error={!!message}
                            helperText={message || ""}
                          />
                        )}
                      />
                    )
                  }}

                />

              </Grid>
              <Grid item sm={12} xs={12}>
                <CustomSelect
                  controllerName="category"
                  controllerLabel="Category"
                  optionsArray={[{ label: "One Time", value: "1" }, { label: "Multiple Time", value: "2" },]}
                  key={`Category-controllerLabel`}
                />
              </Grid>

              <Grid item sm={12} xs={12}>
                <CustomSelect
                  controllerName="type"
                  controllerLabel="Type"
                  optionsArray={[{ label: "Fixed Amount", value: "1" }, { label: "Percentage", value: "2" },]}
                  key={`type-controllerLabel`}
                  disable={!!selectedCoupon}
                />
              </Grid>

              <Grid item sm={12} xs={12}>
                <CustomController
                  controllerName="discountValue"
                  controllerLabel="Discount"
                  fieldType="text"
                  key={`discountValue-controllerLabel`}
                />
              </Grid>


              <Grid item sm={6} xs={12}>
                <CustomController
                  controllerName="startDate"
                  controllerLabel="Start Date"
                  fieldType="date"
                  key={`startDate-controllerLabel`}
                  disabled={disableStartDate}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <CustomController
                  controllerName="endDate"
                  controllerLabel="End Date"
                  fieldType="date"
                  key={`endDate-controllerLabel`}
                />
              </Grid>

              <Grid item sm={6} xs={6}>
                <FormControlLabel
                  control={
                    <Controller
                      name="isNewCustomer"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          checked={!!field.value}
                          onChange={(e) => field.onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                  label="New Customer Only"
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button variant="contained" type="submit" color="primary" disabled={loading}>
              {(loading) ? (
                <CircularProgress size={20} />
              ) : <>
                {selectedCoupon ? "Update" : "Create"}
              </>
              }
            </Button>

            <Button onClick={handleClose} color="primary" disabled={loading}>
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </FormProvider>
  )
}

export default SectionModal