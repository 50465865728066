import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#ffb3c3',
      darker: '#053e85',
    },
    secondary: {
      main: '#046718',
    },
    textPrimary: {
      main: 'black',
    },
    appGray: {
      main: '#A8A8A8',
    },
    appLightGray: {
      main: '#e0e0e0',
    },
  },
  status: {
    danger: '#ff0000',
  },
});
