import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Autocomplete,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import { endpoints } from "../../../config/apiConfig";
import axios from "axios";
import TypoGraphyComponent from "../../atoms/typography";
import { useStyles } from "./styles";
import { getColumns } from "./columnsConfig";
import { DataGrid } from "@mui/x-data-grid";
import ReviewModal from "../../molecules/ReviewModal/ReviewModal";
import ConfirmationModal from "../../atoms/alert/ConfirmationDeleteAlert";
import * as utilityService from "../../../services/utilityService";
import toast, { Toaster } from "react-hot-toast";

function ReviewsScreen(props) {
  const limit = 20;
  const classes = useStyles();
  const [reviews, setReviews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedReview, setSelectedReview] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteReview, setDeleteReview] = useState(null);
  const [listers, setListers] = useState([]);

  // filters
  const [isReviewedByLister, setIsReviewedByLister] = useState(false);
  const [isManualReview, setIsManualReview] = useState(false);
  const [selectedLister, setSelectedLister] = useState(null);

  const accessToken = localStorage.getItem("_token");

  const fetchReviews = async (params) => {
    setIsLoading(true);
    const response = await axios.get(endpoints.reviews.getAll, {
      headers: {
        "Content-Type": "application/json-patch+json",
        Authorization: `Bearer ${accessToken}`,
      },
      params,
    });

    if (response.data.success) {
      const { data } = response.data.data;
      setReviews(data);
      setTotalRecords(response.data.total);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setReviews([]);
      setTotalRecords(0);
    }
  };

  const fetchAllUsers = async (params) => {
    const response = await axios.get(endpoints.reviews.topListers, {
      headers: {
        "Content-Type": "application/json-patch+json",
        Authorization: `Bearer ${accessToken}`,
      },
      params,
    });
    if (response.data.success) {
      const mappedData = utilityService.mapTopListerData(
        response.data.data.data
      );
      setListers(mappedData);
    } else {
      setListers([]);
    }
  };

  useEffect(() => {
    fetchAllUsers({
      IsTopRatedLister: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log("selectedLister", selectedLister);
    fetchReviews({
      limit: limit,
      Current: currentPage,
      IsReviewedByLister: isReviewedByLister,
      IsManualReview: isManualReview,
      ...(selectedLister ? { userId: selectedLister.id } : {}),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, selectedLister, isReviewedByLister, isManualReview]);

  const onDelete = (row) => {
    setDeleteReview(row);
    setOpenDelete(true);
  };

  const columns = getColumns(onDelete);
  const handlePageChange = (params) => {
    setCurrentPage(params + 1);
  };
  const handleCloseModal = () => {
    setSelectedReview(null);
  };

  const handleDeleteSubmission = async (id) => {
    try {
      setDeleteLoading(true);
      const updatedReviews = [];
      const response = await axios.delete(`${endpoints.reviews.getAll}/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("_token")}`,
        },
      });
      toast.success("Review deleted successfully");
      for (let review of reviews) {
        if (review.id !== id) {
          updatedReviews.push(review);
        }
      }
      setReviews(updatedReviews);
      setDeleteLoading(false);
      setOpenDelete(false);
      deleteReview(null);
    } catch (error) {
      setDeleteLoading(false);
    }
  };

  const filterOptions = (options, { inputValue }) => {
    return options.filter(
      (option) =>
        option.email.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.name.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      className={classes.container}
    >
      <Toaster
        toastOptions={{
          className: "",
          style: {
            color: "#FFB3C3",
            backgroundColor: "rgba(40, 0, 0, 0.87)",
            fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
          },
        }}
      />
      <Box className={classes.header}>
        <TypoGraphyComponent text="Reviews" variant="h5" color="primary" />
      </Box>
      <div className="filtersWrapper">
        <Autocomplete
          options={listers}
          getOptionLabel={(option) => `${option.email} - ${option.name}`}
          filterOptions={filterOptions}
          renderOption={(props, option) => (
            <Box
              {...props}
              key={option.userId}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography>{`${option.email} (${option.name})`}</Typography>
            </Box>
          )}
          value={selectedLister}
          onChange={(event, newValue) => {
            setSelectedLister(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Search by user" />
          )}
          style={{ width: "45%", marginRight: "20px" }}
        />
        <FormControlLabel
          control={
            <Checkbox
              onChange={(event) => {
                setIsManualReview(event.target.checked);
                setCurrentPage(1);
              }}
            />
          }
          label="Manual Review"
        />
        <FormControlLabel
          control={
            <Checkbox
              value={isReviewedByLister}
              onChange={(event) => {
                setIsReviewedByLister(event.target.checked);
                setCurrentPage(1);
              }}
            />
          }
          label="Reviewed By Lister"
        />
      </div>
      <Box className={classes.tableContainer}>
        <DataGrid
          rows={reviews}
          columns={columns}
          disableColumnMenu
          pagination
          disableSelectionOnClick
          loading={isLoading}
          pageSize={limit}
          rowCount={totalRecords}
          paginationMode="server"
          onPageChange={handlePageChange}
          onRowClick={(row) => {
            console.log(row.row);
            setSelectedReview(row.row);
          }}
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              textOverflow: "clip",
              whiteSpace: "break-spaces",
              lineHeight: 1,
            },
          }}
        />
      </Box>
      {selectedReview ? (
        <ReviewModal
          open={selectedReview !== null}
          onClose={handleCloseModal}
          review={selectedReview}
        />
      ) : null}
      <ConfirmationModal
        isOpen={openDelete}
        setOpen={setOpenDelete}
        title="Delete Review"
        description={`Do you really want to delete this review?`}
        isLoading={deleteLoading}
        handleDelete={
          deleteReview ? () => handleDeleteSubmission(deleteReview) : null
        }
        actionText="Confirm"
      />
    </Grid>
  );
}

export default ReviewsScreen;
