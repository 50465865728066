import React, { useState } from "react";
import { Grid, Paper, InputAdornment, Snackbar, Alert, CircularProgress } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import InputField from "../../atoms/input";
import { useStyles } from "../../../style";
import TypoGraphyComponent from "../../atoms/typography";
import ButtonComponent from "../../atoms/button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { endpoints } from "../../../config/apiConfig";
import logService from "../../../services/logService";
import ReCAPTCHA from "react-google-recaptcha";

const LoginScreen = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [disable, setDisable] = useState(true);
  const [transition, setTransition] = React.useState(undefined);
  const [error, setError] = useState("");
  const [token, setToken] = useState("");
  const [values, setValues] = useState({
    email: "",
    password: "",
    showPassword: false,
  });

  const handleChange = (prop) => (e) => {
    setValues({ ...values, [prop]: e.target.value });
  };

  const handlePasswordVisibility = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const result = await axios.get(`${endpoints.auth.AdminLogin}`, {
        params: { email: values.email, password: values.password },
      });

      if (result.data.success) {
        setLoading(false);
        localStorage.setItem("_token", result.data.data.access_token);
        navigate("/listings");
      } else {
        setOpen(true);
        setLoading(false);
        setError(result.data.message);
        logService.logError(
          `file: login.jsx, function: handleSubmit, message: ${result.data.message}`
        );
      }
    } catch (error) {
      logService.logError(`file: login.jsx, function: handleSubmit, message: ${error.message}`);
    }
  };

  const handleCaptchaChange = (value) => {
    if (value !== null) {
      setCaptchaToken(value);
      setDisable(false);
    } else {
      setDisable(true);
    }
  };
  return (
    <>
      <Paper className={classes.paper} variant="elevation" elevation={2}>
        <Grid item>
          <Snackbar
            open={open}
            onClose={() => setOpen(false)}
            TransitionComponent={transition}
            key={transition ? transition.name : ""}
          >
            <Alert severity="error">{error}!</Alert>
          </Snackbar>

          <TypoGraphyComponent
            variant="h5"
            text="Sign In To Dashboard"
            align="center"
            color="textPrimary"
            gutterBottom="gutterBottom"
          />
        </Grid>
        <Grid item>
          <form onSubmit={handleSubmit}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <InputField
                  type="email"
                  placeholder="Email"
                  name="username"
                  variant="outlined"
                  label="Name / email"
                  required
                  autoFocus
                  value={values.email}
                  onChange={handleChange("email")}
                />
              </Grid>
              <Grid item>
                <InputField
                  type={values.showPassword ? "text" : "password"}
                  placeholder="Password"
                  fullWidth
                  name="password"
                  variant="outlined"
                  label="Password"
                  required
                  autoFocus
                  value={values.password}
                  onChange={handleChange("password")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton onClick={handlePasswordVisibility}>
                          {values.showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item>
                <ReCAPTCHA
                  style={{ marginTop: 10 }}
                  onChange={handleCaptchaChange}
                  sitekey={endpoints.GoogleCaptcha.siteKey}
                />
              </Grid>
              <Grid item>
                <ButtonComponent
                  disabled={loading ? "disabled" : disable ? "disabled" : ""}
                  variant="contained"
                  color="primary"
                  text={loading ? "Signing In....." : "Sign In"}
                  type="submit"
                  fullWidth="fullWidth"
                />
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Paper>
    </>
  );
};

export default LoginScreen;
