// Date when we released the transaction fee implementation
// can be removed when there is a renter fee column
const renterFeeReleaseDate = new Date("January 4, 2023 22:06:00");
export function mapReservationData(data) {
  const result =
    data.allReservations &&
    data.allReservations.map((row, index) => {
      const {
        swishedProduct: product,
        swishedUser,
        stripePaymentDetails: paymentDetail,
        deliveryOptions: deliveryType,
      } = row;
      const variant = product ? product.variants[0] : null;
      const productUser = product ? product.swishedUser : null;
      return {
        index: index,
        id: row.id,
        createdOn: row.createdOn,
        startDate: row.startDate,
        endDate: row.endDate,
        status: row.status,
        webReservation: row.webReservation ? "Yes" : "No",
        isPaymentDetailAdded: row.isPaymentDetailAdded ? "Paid" : "Unpaid",
        deliveryOptions: deliveryType === null ? "NiL" : deliveryType.option,
        totalAmount: paymentDetail ? paymentDetail.totalAmount : 0,
        renter: swishedUser ? swishedUser.name : null,
        renterEmail: swishedUser ? swishedUser.email : null,
        lister: productUser ? productUser.name : null,
        listerEmail: productUser ? productUser.email : null,
        product_id: row.productId,
        title: product ? product.title : null,
        size: variant ? variant.size : null,
        colour: variant ? variant.colour : null,
        price: paymentDetail ? paymentDetail.amount : 0,
        retailPrice: variant ? variant.retailPrice : null,
        discountCode:
          paymentDetail && paymentDetail.discountCode
            ? paymentDetail.discountCode
            : "N/A",
        discountedAmount:
          paymentDetail && paymentDetail.discountedAmount
            ? paymentDetail.discountedAmount.toFixed(2)
            : 0,
        damageProtectionFee:
          paymentDetail && paymentDetail.damageProtectionFee
            ? paymentDetail.damageProtectionFee.toFixed(2)
            : 0,
        totalWithoutDiscount: (
          Number(paymentDetail ? paymentDetail.amount : 0) +
          Number(deliveryType?.value)
        ).toFixed(2),
        transactionFee:
          new Date(row.createdOn) > renterFeeReleaseDate
            ? calculateTransactionFee(
                variant?.price,
                deliveryType?.value,
                paymentDetail?.damageProtectionFee,
                paymentDetail?.discountedAmount
              )
            : 0,
        isPaidToConnectedAccount: paymentDetail?.isPaidToConnectedAccount
          ? "YES"
          : "NO",
        paymentStatus: paymentDetail?.paymentStatus,
        paymentStatusName: paymentDetail?.paymentStatusName,
        isPurchased: row.isPurchased ? "Yes" : "No",
        resalePrice:
          variant && variant.resalePrice ? variant.resalePrice : "N/A",
      };
    });
  return result;
}

export function mapGiftCardsData(data) {
  const result = data.map((row, index) => {
    return {
      index: index,
      id: row.id,
      giftCardCode: row.giftCardCode,
      giftCardAmount: row.giftCardAmount,
      buyerName: row.buyerName,
      buyerEmail: row.buyerEmail,
      recipientName: row.recipientName,
      recipientEmail: row.recipientEmail,
      reminderDate: row.reminderDate,
      isReminderSent: row.isReminderSent,
      personalizedMessage: row.personalizedMessage,
      remainingBalance: row.remainingBalance,
      paymentIntentId: row.paymentIntentId,
      purchaseDate: row.purchaseDate,
    };
  });
  return result;
}

export function mapTopListerData(data) {
  const result = data.map((row, index) => {
    return {
      index: index,
      id: row.userId,
      userId: row.userId,
      name: row.name,
      email: row.email,
      isTopRatedLister: row.isTopRatedLister ? "Yes" : "No",
    };
  });
  return result;
}

export const calculateRental = (
  rentalPrice = 0,
  deliveryCharges = 0,
  damageProtectionFee = 0,
  discount = 0
) => {
  // when null is passed default initialization do not kick in
  if (!damageProtectionFee) {
    damageProtectionFee = 0;
  }

  let totalAmount =
    parseFloat(rentalPrice) +
    parseFloat(deliveryCharges) +
    parseFloat(damageProtectionFee) -
    parseFloat(discount);

  // keep final amount to 2 decimal places
  totalAmount = parseFloat(totalAmount.toFixed(2));
  return totalAmount;
};

export const calculateTransactionFee = (
  rentalPrice = 0,
  deliveryCharges = 0,
  damageProtectionFee = 0,
  discount = 0
) => {
  // when null is passed default initialization do not kick in
  if (!damageProtectionFee) {
    damageProtectionFee = 0;
  }

  let totalAmount = calculateRental(
    rentalPrice,
    deliveryCharges,
    damageProtectionFee,
    discount
  );
  const transactionFee = parseFloat((totalAmount * 0.025).toFixed(2));
  return transactionFee;
};
