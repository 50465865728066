import React from "react";
import { Box, Typography, Rating, Modal, Grid, Paper } from "@mui/material";

function ReviewModal({ open, onClose, review }) {
  return (
    <Modal open={open} onClose={onClose} aria-labelledby="review-modal-title">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          maxWidth: "600px",
          maxHeight: "80vh",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          overflowY: "auto",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              id="review-modal-title"
              variant="h6"
              component="h2"
              gutterBottom
            >
              Review Details
            </Typography>
          </Grid>
          <Grid item xs={12} container alignItems="center" spacing={2}>
            <Grid item>
              <Typography variant="subtitle1">
                @{review.reviewerUsername}
              </Typography>
              <Rating name="read-only" value={review.starRating} readOnly />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">{review.comment}</Typography>
          </Grid>
          {review?.reviewArtifacts?.map((artifact) => (
            <Grid item xs={6} key={artifact.id}>
              <Paper elevation={3}>
                <img
                  src={artifact.artifactUrl}
                  alt="Review Artifact"
                  style={{ width: "100%", height: "auto" }}
                />
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Modal>
  );
}

export default ReviewModal;
