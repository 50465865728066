// packages block
import React, { useState } from "react";
import { TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
// components block
import { ShowPassword } from "./ShowPassword";

export const PASSWORD = "password";
export const TEXT = "text";

const CustomController = ({ controllerName, controllerLabel, fieldType, isPassword, disabled, isMulti }) => {
  const [passwordType, setPasswordType] = useState(PASSWORD);
  const { control } = useFormContext();

  const handleClickShowPassword = () => {
    if (passwordType === PASSWORD) {
      setPasswordType(TEXT);
    } else {
      setPasswordType(PASSWORD);
    }
  };

  return (
    <Controller
      name={controllerName}
      control={control}
      render={({ field, fieldState: { invalid, error: { message } = {} } }) => (
        <TextField
          type={fieldType === "password" ? passwordType : fieldType}
          variant="outlined"
          error={invalid}
          disabled={disabled}
          label={controllerLabel}
          fullWidth
          {...field}
          multiline={isMulti ? true : false}
          minRows={isMulti && 4}
          helperText={message && message}
          margin="normal"
          InputProps={isPassword ? {
            endAdornment: (
              <ShowPassword
                isPassword={isPassword}
                passwordType={passwordType}
                handleShowPassword={handleClickShowPassword}
              />
            )
          } : undefined}

          InputLabelProps={fieldType === "date" ? {
            shrink: true
          } : undefined}
        />
      )}
    />
  );
};

export default CustomController;