import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { endpoints } from "../../../config/apiConfig";
import axios from "axios";
import TypoGraphyComponent from "../../atoms/typography";
import { useStyles } from "./styles";
import { getColumns } from "./columnsConfig";
import * as utilityService from "../../../services/utilityService";
import { DataGrid } from "@mui/x-data-grid";

function GiftCardScreen(props) {
  const limit = 20;
  const classes = useStyles();
  const [giftcards, setGiftcards] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const accessToken = localStorage.getItem("_token");

  const fetchGiftcards = async (params) => {
    setIsLoading(true);
    const response = await axios.get(endpoints.giftCards.getAll, {
      headers: {
        "Content-Type": "application/json-patch+json",
        Authorization: `Bearer ${accessToken}`,
      },
      params,
    });

    if (response.data.success) {
      const { data } = response.data;
      const mappedData = utilityService.mapGiftCardsData(data);
      console.log(mappedData);
      setGiftcards(mappedData);
      setGiftcards(response.data.data);
      setTotalRecords(response.data.total);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchGiftcards({
      PageSize: limit,
      PageNumber: currentPage,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const columns = getColumns();
  const handlePageChange = (params) => {
    setCurrentPage(params + 1);
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      className={classes.container}
    >
      <Box className={classes.header}>
        <TypoGraphyComponent text="Giftcards" variant="h5" color="primary" />
      </Box>
      <Box className={classes.tableContainer}>
        <DataGrid
          rows={giftcards}
          columns={columns}
          disableColumnMenu
          pagination
          disableSelectionOnClick
          loading={isLoading}
          pageSize={limit}
          rowCount={totalRecords}
          paginationMode="server"
          onPageChange={handlePageChange}
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              textOverflow: "clip",
              whiteSpace: "break-spaces",
              lineHeight: 1,
            },
          }}
        />
      </Box>
    </Grid>
  );
}

export default GiftCardScreen;
