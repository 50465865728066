import { Grid, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { Route, Routes } from "react-router-dom";
import DrawerComponent from "../../molecules/drawer";
import ListingScreen from "../../screens/listings";
import ReservationScreen from "../../screens/reservations";
import CarouselScreen from "../../screens/carousel";
import ProtectedRoute from "../../../ProtectedRoute";
import Coupon from "../../screens/coupon";
import Collections from "../../screens/collections";
import CreateCollection from "../../screens/collections/CreateCollection";
import EditCollection from "../../screens/collections/EditCollection";
import GiftcardsScreen from "../../screens/giftcards";
import ReviewsScreen from "../../screens/reviews";
import TopListerScreen from "../../screens/toplisters";
import AddReviewScreen from "../../screens/addreviews";

const useStyles = makeStyles({
  rootContainer: {
    // background: '#F7F7F7',
    minHeight: "100vh",
    minWidth: "100vw",
    height: "100vh",
    width: "100vw",
    color: "#000133",
  },
  routeContainer: {
    height: "100vh",
    overflowY: "auto",
  },
  drawerContainer: {
    height: "100vh",
  },
});

const DashboardComponent = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      spacing={0}
      className={classes.rootContainer}
    >
      <Grid item xs={2} className={classes.drawerContainer}>
        <DrawerComponent />
      </Grid>
      <Grid item xs={10} className={classes.routeContainer}>
        <Routes>
          <Route
            path="/listings"
            element={
              <ProtectedRoute redirectTo="/">
                <ListingScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reservations"
            element={
              <ProtectedRoute redirectTo="/">
                <ReservationScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/carousel"
            element={
              <ProtectedRoute redirectTo="/">
                <CarouselScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/discount"
            element={
              <ProtectedRoute redirectTo="/">
                <Coupon />
              </ProtectedRoute>
            }
          />
          <Route
            path="/collections"
            element={
              <ProtectedRoute redirectTo="/">
                <Collections />
              </ProtectedRoute>
            }
          />
          <Route
            path="/gift-cards"
            element={
              <ProtectedRoute redirectTo="/">
                <GiftcardsScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reviews"
            element={
              <ProtectedRoute redirectTo="/">
                <ReviewsScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/top-listers"
            element={
              <ProtectedRoute redirectTo="/">
                <TopListerScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/add-review"
            element={
              <ProtectedRoute redirectTo="/">
                <AddReviewScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/create-collections"
            element={
              <ProtectedRoute redirectTo="/">
                <CreateCollection />
              </ProtectedRoute>
            }
          />
          <Route
            path="/edit-collection/:id"
            element={
              <ProtectedRoute redirectTo="/">
                <EditCollection />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Grid>
    </Grid>
  );
};

export default DashboardComponent;
