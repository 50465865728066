import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  carouselItem: {
    width: '80%',
    minHeight: 130,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 5,
    paddingBottom: 5,
    borderBottom: '1px solid lightgray',
    display: 'flex',
    flexDirection: 'column',
  },
  carouselContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  carouselItemLeft: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  carouselItemFreeSpace: {
    flex: 1,
  },
  carouselItemRight: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },
  browseButtonContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },
  iconButton: {
    cursor: 'pointer',
  },
  fileNameContainer: {
    marginLeft: 20,
    cursor: 'pointer',
  },
  uploadButton: {
    marginRight: 10,
  },
  mediaComponent: {
    borderRadius: 3,
    cursor: 'pointer',
  },
  assetTitleText: {
    paddingBottom: 10,
  },

  linkContainer: {
    marginTop: 15,
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'row',
  },
  linkFieldContainer: {
    flex: 1,
    marginRight: 10,
  },
});
