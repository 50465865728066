import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Autocomplete,
  FormControlLabel,
  Switch,
  CircularProgress,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function AddUpdateTopListerModal({
  open,
  onClose,
  users,
  updateLister,
  updateLoading,
  onUpdate,
}) {
  const [selectedLister, setSelectedLister] = useState(null);
  const [isTopRated, setIsTopRated] = useState(false);

  useEffect(() => {
    if (updateLister) {
      setSelectedLister(updateLister);
      setIsTopRated(updateLister?.isTopRatedLister === "Yes");
    } else {
      setSelectedLister(null);
      setIsTopRated(false);
    }
  }, [updateLister]);

  const filterOptions = (options, { inputValue }) => {
    return options.filter(
      (option) =>
        option.email.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.name.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="update-lister-modal"
      aria-describedby="modal-to-update-lister-status"
    >
      <Box sx={style}>
        <Typography id="update-lister-modal" variant="h6" component="h2">
          Update Lister Status
        </Typography>
        <Autocomplete
          options={users}
          getOptionLabel={(option) => `${option.name} - ${option.email}`}
          filterOptions={filterOptions}
          renderOption={(props, option) => (
            <Box
              {...props}
              key={option.userId}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography>{`${option.email} (${option.name})`}</Typography>
            </Box>
          )}
          disabled={updateLister !== null}
          value={selectedLister}
          onChange={(event, newValue) => {
            setSelectedLister(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Search Listers" />
          )}
          fullWidth
          margin="normal"
        />

        <FormControlLabel
          control={
            <Switch
              checked={isTopRated}
              onChange={(e) => setIsTopRated(e.target.checked)}
            />
          }
          label="Is Top Rated Lister"
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => {
            onUpdate(selectedLister, isTopRated);
          }}
          disabled={updateLoading}
          sx={{ mt: 2 }}
        >
          {updateLoading ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            "Update"
          )}
        </Button>
      </Box>
    </Modal>
  );
}

export default AddUpdateTopListerModal;
