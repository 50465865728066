const rootURL = "https://api.swished.app/api";

export const endpoints = {
  coupons: {
    getCoupons: `${rootURL}/DiscountCode/list`,
    createOrUpdateCoupon: `${rootURL}/DiscountCode`,
    activeDeactivateCoupon: `${rootURL}/DiscountCode/active`,
  },
  products: {
    product: `${rootURL}/Product`,
    getProductsForDashboard: `${rootURL}/Product/GetProductsForDashboard`,
    updateProductsPosition: `${rootURL}/Product/changeposition`,
    getProductCategories: `${rootURL}/CategoryVariant`,
    rearrangeProductPositions: `${rootURL}/v7/Product/RearrangeProductPositions`,
  },
  auth: {
    AdminLogin: `${rootURL}/Account/AdminLogin`,
  },
  keys: {
    S3Keys: "/S3Keys",
  },
  productReservation: {
    getAllReservations: `${rootURL}/ProductReservationDetails/GetAllReservations`,
    deleteReservations: `${rootURL}/ProductReservationDetails/DeleteReservations`,
    getReturnAddress: `${rootURL}/ReturnAdress`,
  },
  carousel: {
    getCarouselData: `${rootURL}/Carousel/GetCarouselArtifacts`,
    addCarouselData: `${rootURL}/Carousel/AddCarouselArtifacts`,
    deleteCarouselData: `${rootURL}/Carousel/DeleteCarouselArtifact`,
  },
  logger: {
    logError: `${rootURL}/ClientSideExceptionLog`,
  },
  baseUrl: rootURL,
  paymentStatus: {
    update: `${rootURL}/v3/StripePayment/update-payment-status`,
  },
  productActions: {
    getProductDetailById: `${rootURL}/v7/Product/GetProductByProductAndUserId`,
  },
  collections: {
    getCollections: `${rootURL}/Collections`,
    PostCollections: `${rootURL}/Collections`,
    CollectionsProducts: `${rootURL}/CollectionsProducts`,
  },
  GoogleCaptcha: {
    siteKey: "6LfjrCgmAAAAADTAuohmPkdfYwGUGVDWu6kiLYRM",
  },
  giftCards: {
    getAll: `${rootURL}/GiftCard/GetAllPurchaseDetails`,
  },
  reviews: {
    getAll: `${rootURL}/Review`,
    topListers: `${rootURL}/Profile/GetUserProfiles`,
    updateTopLister: `${rootURL}/Profile/UpdateTopRatedListerStatus`,
    addManualReview: `${rootURL}/Review/AddManualReview`,
  },
};
