import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  root: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  paper: {
    padding: "30px 30px 50px",
  },
});
