// packages block
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Select, FormControl, InputLabel, MenuItem, FormHelperText } from '@mui/material'

const Selector = ({ disable, controllerName, controllerLabel, selectedOption, optionsArray, }) => {
  const { control, setValue } = useFormContext();

  useEffect(() => {
    if (selectedOption) {
      setValue(controllerName, selectedOption)
    }
  }, [selectedOption, setValue, controllerName]);

  return (
    <Controller
      name={controllerName}
      key={controllerName}
      control={control}
      render={({ field, fieldState: { error: { message } = {} } }) => {
        return (
          <FormControl variant="outlined" fullWidth error={Boolean(message)} margin="normal">
            <InputLabel>{controllerLabel}</InputLabel>
            <Select
              name={controllerName}
              id={`${controllerName}-${controllerLabel}`}
              labelId={controllerLabel}
              label={controllerLabel}
              onChange={field.onChange}
              disabled={disable}
              value={field.value}
            >
              {optionsArray.map(({ label, value }) => (
                <MenuItem value={value}>{label}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{message && message}</FormHelperText>
          </FormControl>
        )
      }}
    />

  )
}

export default Selector;
