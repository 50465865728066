import { React, useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TypoGraphyComponent from "../../atoms/typography";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { getColumns } from "./columnsConfig";
import { endpoints } from "../../../config/apiConfig";
import logService from "../../../services/logService";
import ButtonComponent from "../../atoms/button";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useStyles } from "./styles";
import * as utilityService from "../../../services/utilityService";
import ConfirmationDialog from "../../ConfirmationDialog/index";
import {
  BOOKING_STATUS,
  PAGE_LIMIT as limit,
  PAYMENT_STATUS,
} from "../../../constants/reservationConstants";
import * as productActions from "../../../redux/actions/ReturnAddressActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const ReservationScreen = (props) => {
  const { pdpProduct } = props;
  const classes = useStyles();
  const [reservationProducts, SetReservationProducts] = useState([]);
  const [totalRecords, SetTotalRecords] = useState(0);
  const [loading, SetLoading] = useState(false);
  const [currentPage, SetCurrentPage] = useState(1);
  const [selectionModel, setSelectionModel] = useState([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showAddressDialog, setShowAddressDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [returnAddress, setReturnAddress] = useState();
  const [isPurchased, setIsPurchased] = useState(false);

  const deleteableStatuses = [
    BOOKING_STATUS.BOOKED,
    BOOKING_STATUS.TO_POST,
    BOOKING_STATUS.LIVE,
  ];

  const fetchProducts = async (params) => {
    SetLoading(true);
    try {
      const response = await axios.get(
        `${endpoints.productReservation.getAllReservations}`,
        {
          params,
        }
      );

      if (response.status === 200 && response.data.success) {
        const { data } = response.data;
        const mappedData = utilityService.mapReservationData(data);
        SetReservationProducts(mappedData);
        SetTotalRecords(response.data.total);
      }
      SetLoading(false);
    } catch (error) {
      logService.logError(
        `file: reservations> index.js, function: fetchProducts, message: ${error.message}`
      );
    }
  };

  const handlePageChange = (params) => {
    SetCurrentPage(params + 1);
  };

  const handleDeleteClick = () => {
    setShowDeleteDialog(true);
  };

  const deleteSelectedReservations = async () => {
    setShowDeleteDialog(false);
    SetLoading(true);
    let accessToken = localStorage.getItem("_token");
    const response = await axios.delete(
      `${endpoints.productReservation.deleteReservations}`,
      {
        headers: {
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${accessToken}`,
        },
        data: selectionModel,
      }
    );

    if (response.status === 200 && response.data.success) {
      const updatedList = reservationProducts.filter(
        (item) => !selectionModel.includes(item.id)
      );
      SetReservationProducts(updatedList);
      setSelectionModel([]);
      toast.success("Successfully deleted !");
    } else {
      toast.error("Something went wrong while deleting the reservation.");
    }
    SetLoading(false);
  };

  const showAddress = async (reservationId) => {
    let accessToken = localStorage.getItem("_token");
    const response = await axios.get(
      `${endpoints.productReservation.getReturnAddress}?resevationId=${reservationId}`,
      {
        headers: {
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.data.success) {
      setShowAddressDialog(true);
      setReturnAddress(response.data.data);
    } else {
      toast.error("Address not found");
    }
  };

  // payment menu
  const openMenu = (params, event) => {
    setSelectedRow(params.row);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handlePaymentStatus = async (status) => {
    let accessToken = localStorage.getItem("_token");
    const response = await axios.post(
      `${endpoints.paymentStatus.update}`,
      {
        reservationId: selectedRow.id,
        paymentStatus: status,
      },
      {
        headers: {
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.status === 200 && response.data.success) {
      const updatedList = reservationProducts.map((item) => {
        if (item.id === selectedRow.id) {
          item.paymentStatus = status;
          item.paymentStatusName = Object.keys(PAYMENT_STATUS).find(
            (key) => PAYMENT_STATUS[key] === status
          );
        }
        return item;
      });
      SetReservationProducts(updatedList);
      toast.success("Successfully updated !");
      handleClose();
    } else {
      toast.error("Something went wrong while updating the reservation.");
    }
  };

  const handleisPurchasedChange = (isPurchased) => {
    fetchProducts({
      limit,
      current: currentPage,
      isPurchased,
    });
  };

  const columns = getColumns(
    openMenu,
    showAddress,
    handleisPurchasedChange,
    isPurchased,
    setIsPurchased
  );

  useEffect(() => {
    fetchProducts({
      limit,
      current: currentPage,
      isPurchased,
    });
  }, [currentPage]);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      className={classes.container}
    >
      <Toaster
        toastOptions={{
          className: "",
          style: {
            color: "#FFB3C3",
            backgroundColor: "rgba(40, 0, 0, 0.87)",
            fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
          },
        }}
      />
      <Box className={classes.header}>
        <TypoGraphyComponent text="Reservations" variant="h5" color="primary" />
        <Box className={classes.headerOptions}>
          <Box className={classes.headerOptionsLeft}></Box>
          <Box className={classes.headerFreeSpace}></Box>
          <Box className={classes.headerOptionsRight}>
            {selectionModel.length > 0 ? (
              <Box className={classes.delete}>
                <ButtonComponent
                  style={{ padding: 0 }}
                  variant="text"
                  color="primary"
                  text="Delete"
                  onClick={handleDeleteClick}
                  align="center"
                  startIcon={<DeleteOutlinedIcon />}
                />
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
      <Box className={classes.tableContainer}>
        <DataGrid
          rows={reservationProducts}
          columns={columns}
          disableColumnMenu
          pagination
          checkboxSelection
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
          disableSelectionOnClick
          loading={loading}
          pageSize={limit}
          rowCount={totalRecords}
          paginationMode="server"
          onPageChange={handlePageChange}
          isRowSelectable={(params) =>
            deleteableStatuses.includes(params.row.status)
          }
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              textOverflow: "clip",
              whiteSpace: "break-spaces",
              lineHeight: 1,
            },
          }}
        />
        <Menu
          id="menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl) && selectedRow ? true : false}
          onClose={handleClose}
        >
          {selectedRow &&
          selectedRow.paymentStatus === PAYMENT_STATUS.Pending ? (
            <MenuItem onClick={() => handlePaymentStatus(PAYMENT_STATUS.Hold)}>
              Hold Payment
            </MenuItem>
          ) : null}
          {selectedRow && selectedRow.paymentStatus === PAYMENT_STATUS.Hold ? (
            <MenuItem
              onClick={() => handlePaymentStatus(PAYMENT_STATUS.Pending)}
            >
              Resume Payment
            </MenuItem>
          ) : null}
          <MenuItem
            onClick={() => handlePaymentStatus(PAYMENT_STATUS.Canceled)}
          >
            Cancel Payment
          </MenuItem>
        </Menu>
      </Box>
      <ConfirmationDialog
        open={showDeleteDialog}
        title="Manual Actions Required!"
        message={[
          "You are about to “hard” delete a Reservation. You will need to do the following manually:",
          "- Inform the Lister and the Renter.",
          "- Refund the Renter.",
          "Are you sure you want to continue?",
        ]}
        acceptTitle="DELETE"
        onAccept={deleteSelectedReservations}
        rejectTitle="CANCEL"
        onReject={() => setShowDeleteDialog(false)}
      />
      <ConfirmationDialog
        open={showAddressDialog}
        title="Return Address of the Renter!"
        message={[
          `Name = ${returnAddress?.name}`,
          `Address Line 1 = ${returnAddress?.addressLine1}`,
          `Address Line 2 = ${returnAddress?.addressLine2}`,
          `Town = ${returnAddress?.town}`,
          `PostCode = ${returnAddress?.postCode}`,
        ]}
        rejectTitle="CANCEL"
        onReject={() => setShowAddressDialog(false)}
      />
    </Grid>
  );
};

const mapStateToProps = ({ product }) => {
  const { pdpProduct } = product;
  return { pdpProduct };
};

const mapDispatchToProps = (dispatch) => {
  return {
    productActions: bindActionCreators(productActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReservationScreen);
