import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Autocomplete,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  Paper,
} from "@mui/material";
import { endpoints } from "../../../config/apiConfig";
import axios from "axios";
import TypoGraphyComponent from "../../atoms/typography";
import { useStyles } from "./styles";
import * as utilityService from "../../../services/utilityService";
import toast, { Toaster } from "react-hot-toast";

function AddReviewScreen(props) {
  const classes = useStyles();
  const [listers, setListers] = useState([]);
  const [selectedLister, setSelectedLister] = useState(null);
  const [isReviewedByLister, setIsReviewedByLister] = useState(false);
  const [isReviewedByRenter, setIsReviewedByRenter] = useState(false);
  const [reviewCount, setReviewCount] = useState(0);

  const accessToken = localStorage.getItem("_token");

  const addManualReview = async () => {
    if (!selectedLister) {
      toast.error("Please select a lister.");
      return;
    }

    try {
      const response = await axios.post(
        endpoints.reviews.addManualReview,
        {
          userId: selectedLister.userId,
          isReviewedByLister,
          isReviewedByRenter,
          reviewCount,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.data.success) {
        toast.success("Review added successfully.");
        setSelectedLister(null);
        setIsReviewedByLister(false);
        setIsReviewedByRenter(false);
        setReviewCount(0);
      } else {
        toast.error("Failed to add review.");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while adding the review.");
    }
  };

  const fetchAllUsers = async (params) => {
    const response = await axios.get(endpoints.reviews.topListers, {
      headers: {
        "Content-Type": "application/json-patch+json",
        Authorization: `Bearer ${accessToken}`,
      },
      params,
    });
    if (response.data.success) {
      const mappedData = utilityService.mapTopListerData(
        response.data.data.data
      );
      setListers(mappedData);
    } else {
      setListers([]);
    }
  };

  useEffect(() => {
    fetchAllUsers({
      IsTopRatedLister: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterOptions = (options, { inputValue }) => {
    return options.filter(
      (option) =>
        option.email.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.name.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      className={classes.container}
    >
      <Toaster
        toastOptions={{
          className: "",
          style: {
            color: "#FFB3C3",
            backgroundColor: "rgba(40, 0, 0, 0.87)",
            fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
          },
        }}
      />
      <Box className={classes.header}>
        <TypoGraphyComponent
          text="Add Manual Reviews"
          variant="h5"
          color="primary"
        />
      </Box>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: "5%" }}
      >
        <Paper elevation={3} style={{ padding: "20px", width: "50%" }}>
          <Autocomplete
            options={listers}
            getOptionLabel={(option) => `${option.name} - ${option.email}`}
            filterOptions={filterOptions}
            renderOption={(props, option) => (
              <Box
                {...props}
                key={option.userId}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography>{`${option.email} (${option.name})`}</Typography>
              </Box>
            )}
            value={selectedLister}
            onChange={(event, newValue) => {
              setSelectedLister(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Search Listers" />
            )}
            fullWidth
            margin="normal"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isReviewedByLister}
                onChange={(event) =>
                  setIsReviewedByLister(event.target.checked)
                }
              />
            }
            label="Reviewed by Lister"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isReviewedByRenter}
                onChange={(event) =>
                  setIsReviewedByRenter(event.target.checked)
                }
              />
            }
            label="Reviewed by Renter"
          />
          <TextField
            label="Review Count"
            type="number"
            value={reviewCount}
            onChange={(event) => setReviewCount(event.target.value)}
            fullWidth
            margin="normal"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={addManualReview}
            style={{ marginTop: "20px" }}
          >
            Add Review
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default AddReviewScreen;
