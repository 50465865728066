import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  container: {
    boxSizing: "border-box",
    height: "100%",
    width: "100%",
    flex: 1,
  },
  header: {
    display: "flex",
    alignItems: "center",
    padding: 15,
    border: "1px solid lightgray",
    boxShadow: "0px 0px 2px 0px lightgray",
    flexDirection: "row",
  },
  tableContainer: {
    flex: 1,
    padding: 15,
  },
  addTopListerButton: {
    display: "flex",
    justifyContent: "flex-end",
    padding: 15,
  },
});
