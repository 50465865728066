import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    boxSizing: 'border-box',
    height: '100%',
    width: '100%',
    flex: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: 15,
    border: '1px solid lightgray',
    boxShadow: '0px 0px 2px 0px lightgray',
    flexDirection: 'row',
  },
  tableContainer: {
    flex: 1,
    padding: 15,
  },
  headerOptions: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 30,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  headerOptionsLeft: {
    display: 'flex',
    flexDirection: 'row',
  },
  headerFreeSpace: {
    flex: 1,
  },
  headerOptionsRight: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  delete: {
    display: 'inline',
    minWidth: 100,
  },
});
