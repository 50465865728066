import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import TypoGraphyComponent from "../../atoms/typography";
import toast, { Toaster } from "react-hot-toast";
import { useStyles } from "./styles";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import axios from "axios";
import { endpoints } from "../../../config/apiConfig";
import carouselApi from "../../../api/carouselApi";
import logService from "../../../services/logService";

const CreateCollection = ({ isMulti }) => {
  const classes = useStyles();
  const [productTag, setProductTag] = useState();
  const [isEqual, setIsEqual] = useState();
  const [title, setTitle] = useState("");
  const [collectionImage, setCollectionImage] = useState("");
  const [s3Keys, setS3Keys] = useState({});
  const [loading, setLoading] = useState(false);

  const accessToken = localStorage.getItem("_token");

  useEffect(() => {
    carouselApi
      .getS3Keys(accessToken)
      .then((keysResult) => {
        if (keysResult && keysResult.status === 200 && keysResult.data) {
          const keys = keysResult.data.data;
          setS3Keys(keys);
        }
      })
      .catch((error) => {
        console.log("Unable to get s3 keys", error);
        logService.logError(
          `function: getS3Keys in Edit Listing, client error msg: ${error.status}`
        );
      });
  }, []);

  const handleCreateCollection = async () => {
    if (title === "") {
      toast.error("Title Cannot be empty");
      return;
    }
    if (collectionImage === "") {
      toast.error("Image Cannot be empty");
      return;
    }

    setLoading(true);
    let ImageUrl = await carouselApi.uploadImageToS3(collectionImage, s3Keys);
    const response = await axios.post(
      endpoints.collections.PostCollections,
      {
        title,
        isDeleted: false,
        coverImageUrl: ImageUrl.src,
      },
      {
        headers: {
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.data.success) {
      setTitle("");
      setLoading(false);
      toast.success("Collection Created");
    } else {
      setLoading(false);
    }
  };

  return (
    <>
      <Toaster
        toastOptions={{
          className: "",
          style: {
            color: "#FFB3C3",
            backgroundColor: "rgba(40, 0, 0, 0.87)",
            fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
          },
        }}
      />
      <Box pt={3} component={Paper} p={2} className="collctionsWrapper">
        <TypoGraphyComponent
          gutterBottom="gutterBottom"
          text="Create Collection"
          variant="h5"
          color="primary"
        />
      </Box>

      <Box pt={3} component={Paper} p={2} className={classes.creteCollctionsWrapper}>
        <Link to="/collections" className={classes.backLink}>
          {" "}
          <ArrowBackIosIcon /> Back to Collections{" "}
        </Link>
        <Grid item sm={6} xs={12} md={8} className={classes.formRow}>
          <TextField
            onChange={(e) => setTitle(e.target.value)}
            id="outlined-basic"
            label="Title"
            variant="outlined"
            fullWidth
            value={title}
            placeholder={title ? "" : "Enter Title"} // Conditionally render the placeholder
          />
        </Grid>
        <Grid item sm={6} xs={12} md={8} className={classes.formRow}>
          <TextField
            type="file"
            label="Upload Image"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              accept: "image/*",
            }}
            onChange={(e) => setCollectionImage(e.target.files[0])}
          />
        </Grid>

        {/* <Grid item sm={6} xs={12} md={8} className={classes.formRow}>
          <TextField
            id="outlined-basic"
            label="Description"
            variant="outlined"
            fullWidth
            multiline
            minRows={4}
          />
        </Grid>
        <Box pt={3} component={Paper} p={2} marginBottom={2} fontFamily="Arial">
          <Typography variant="h7" component="h7">
            Conditions
          </Typography>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12} md={8} marginBottom="25px">
              <span>Product must match: </span>
              <FormControlLabel
                value="allcollections"
                name="allcollections"
                control={<Radio />}
                label="All Conditions"
              />
              <FormControlLabel
                value="anycollection"
                name="anycollection"
                control={<Radio />}
                label="Any Condition"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} md={8}>
            <Grid item sm={6} xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Product tag</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={productTag}
                  label="Product Tag"
                  onChange={(e) => setProductTag(e.target.value)}
                >
                  <MenuItem value="color">Color</MenuItem>
                  <MenuItem value="size">Size</MenuItem>
                  <MenuItem value="price">Rental price</MenuItem>
                  <MenuItem value="category">Catagory</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={6} xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Is equal to</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={isEqual}
                  label="Is equal to"
                  onChange={(e) => setIsEqual(e.target.value)}
                >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={6} xs={12} md={4} p={0}>
              <TextField id="outlined-basic" label=" " variant="outlined" type="number" fullWidth />
            </Grid>
          </Grid>
          <Button className={classes.addCollection} color="primary" variant="contained">
            Add another condition
          </Button>
        </Box> 

        <Grid item sm={6} xs={12} md={8}>
          <TextField id="outlined-basic" label="URL Handle" variant="outlined" fullWidth />
        </Grid> */}
        <Grid item sm={12} xs={12} md={12} className={classes.addCollectionFooterWrapper}>
          <Button
            onClick={handleCreateCollection}
            color="primary"
            disabled={loading}
            variant="contained"
            className={classes.addCollectionFooter}
          >
            {loading ? "Saving" : "Save"}
          </Button>
        </Grid>
      </Box>
    </>
  );
};

export default CreateCollection;
