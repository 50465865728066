import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Paper,
  Radio,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import TypoGraphyComponent from "../../atoms/typography";
import toast, { Toaster } from "react-hot-toast";
import { useStyles } from "./styles";
import { Link, useNavigate, useParams } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import axios from "axios";
import { endpoints } from "../../../config/apiConfig";

const CreateCollection = ({ isMulti }) => {
  const classes = useStyles();
  const { id } = useParams();
  const [productTag, setProductTag] = useState();
  const [isEqual, setIsEqual] = useState();
  const [title, setTitle] = useState("");
  const [products, setProducts] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [loading, setLoading] = useState(false);

  const accessToken = localStorage.getItem("_token");

  const navigate = useNavigate();
  const handleCreateCollection = async () => {
    if (title === "") {
      toast.error("Title Cannot be empty");
      return;
    }
    const response = await axios.post(
      endpoints.collections.PostCollections,
      {
        title,
        isDeleted: false,
      },
      {
        headers: {
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.data.success) {
      setTitle("");
      toast.success("Collection Created");
    }
  };

  const fetchProducts = async () => {
    setLoading(true);
    let result = await axios.get(
      `${endpoints.collections.CollectionsProducts}?collectionId=${id}`,
      {
        headers: {
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (result.data.success) {
      setProducts(result.data.data);
      result?.data?.data.map((item) => {
        setSelectionModel((prevModel) => [...prevModel, item.productId]);
      });
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    setLoading(true);
    let result = await axios.put(
      `${endpoints.collections.CollectionsProducts}?collectionId=${id}`,

      selectionModel,
      {
        headers: {
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (result.data.success) {
      setLoading(false);
      navigate("/collections");
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [id]);

  const handleSelectionChange = (id) => {
    const currentSelection = [...selectionModel];
    if (!currentSelection.includes(id)) {
      currentSelection.push(id);
    } else {
      const itemIndex = currentSelection.indexOf(id);
      currentSelection.splice(itemIndex, 1);
    }
    setSelectionModel(currentSelection);
  };

  const loader = (
    <Box className={classes.loderContainer}>
      <CircularProgress />
    </Box>
  );

  return (
    <>
      <Toaster
        toastOptions={{
          className: "",
          style: {
            color: "#FFB3C3",
            backgroundColor: "rgba(40, 0, 0, 0.87)",
            fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
          },
        }}
      />
      <Box pt={3} component={Paper} p={2} className="collctionsWrapper">
        <TypoGraphyComponent text="Create Collection" variant="h5" color="primary" />
      </Box>

      <Box pt={3} p={2} className={classes.creteCollctionsWrapper}>
        <Link to="/collections" className={classes.backLink}>
          {" "}
          <ArrowBackIosIcon /> Back to Collections{" "}
        </Link>
        <Grid item sm={6} xs={12} md={8} className={classes.formRow}>
          <TypoGraphyComponent
            variant="h5"
            color="primary"
            text="Uncheck the products that you want to remove from the collection."
          />
        </Grid>

        <Grid item sm={6} xs={12} md={8}>
          {loading && loader}
          {products &&
            products.map((item) => {
              return (
                <ListItem key={item.productId}>
                  <FormControlLabel
                    className={classes.indexLabel}
                    control={
                      <Checkbox
                        checked={selectionModel.includes(item.productId)}
                        onChange={() => handleSelectionChange(item.productId)}
                      />
                    }
                    label={item.productId}
                  />
                  <ListItemAvatar>
                    <Avatar src={item.productUrl} variant="square"></Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={item.productTitle} />
                </ListItem>
              );
            })}
        </Grid>
        <Grid item sm={12} xs={12} md={12} className={classes.addCollectionFooterWrapper}>
          <Button
            onClick={handleUpdate}
            color="primary"
            variant="contained"
            className={classes.addCollectionFooter}
          >
            Update
          </Button>
        </Grid>
      </Box>
    </>
  );
};

export default CreateCollection;
