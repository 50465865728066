import {
  Drawer,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import LogoutIcon from "@mui/icons-material/Logout";
import React, { Fragment } from "react";
import TypoGraphyComponent from "../../atoms/typography";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import ViewCarouselOutlinedIcon from "@mui/icons-material/ViewCarouselOutlined";
import ButtonComponent from "../../atoms/button";
import { useNavigate, NavLink } from "react-router-dom";
import DiscountIcon from "@mui/icons-material/Discount";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ListAltIcon from "@mui/icons-material/ListAlt";
import Collapse from "@mui/material/Collapse";

const useStyles = makeStyles({
  drawer: {
    width: "100%",
    height: "100%",
    boxSizing: "border-box",
    borderRight: "1px solid lightgray",
  },
  drawerPaper: {},

  logoText: {
    paddingTop: 30,
    paddingBottom: 30,
    fontWeight: "800!important",
  },
  userEmail: {
    padding: 10,
    fontFamily: "sans-serif",
    paddingLeft: 20,
  },
  drawerMenu: {
    flex: 1,
  },
  signoutButton: {
    marginBottom: "100px",
  },
});

const DrawerComponent = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const items = [
    {
      label: "Listings",
      path: "/listings",
      icon: <HourglassBottomIcon />,
    },
    {
      label: "Reservations",
      path: "/reservations",
      icon: <CalendarMonthIcon />,
    },
    {
      label: "Carousel",
      path: "/carousel",
      icon: <ViewCarouselOutlinedIcon />,
    },
    {
      label: "Discounts",
      path: "/discount",
      icon: <DiscountIcon />,
    },
    {
      label: "Collections",
      path: "/collections",
      icon: <DiscountIcon />,
    },
    {
      label: "Gift Cards",
      path: "/gift-cards",
      icon: <CardGiftcardIcon />,
    },
    {
      label: "Reviews",
      icon: <StarBorderIcon />,
      subItems: [
        { label: "Reviews List", path: "/reviews", icon: <ListAltIcon /> },
        {
          label: "Add Review",
          path: "/add-review",
          icon: <AddCircleOutlineIcon />,
        },
        {
          label: "Top Lister",
          path: "/top-listers",
          icon: <AddCircleOutlineIcon />,
        },
      ],
    },
  ];

  const handleLogout = () => {
    localStorage.removeItem("_token");
    navigate("/");
  };
  const [openReviews, setOpenReviews] = React.useState(false);
  const handleClickReviews = () => {
    setOpenReviews(!openReviews);
  };

  return (
    <Fragment>
      <Drawer
        id="left-drawer"
        PaperProps={{
          sx: {
            flex: 1,
            boxSizing: "border-box",
            position: "inherit",
            border: "none",
            background: "#F7F7F7",
            height: "inherit",
          },
        }}
        variant="permanent"
        anchor="left"
        className={classes.drawer}
      >
        <TypoGraphyComponent
          color="red"
          variant="h5"
          text=".SWISHED"
          component="span"
          align="center"
          className={classes.logoText}
        />
        <TypoGraphyComponent
          className={classes.userEmail}
          variant="p"
          text="admin@swished.app"
          component="span"
        />

        <MenuList className={classes.drawerMenu}>
          {items.map((item, index) => {
            return (
              <Fragment key={index}>
                <MenuItem
                  component={item.subItems ? "div" : NavLink}
                  to={item.path || "#"}
                  onClick={item.subItems ? handleClickReviews : null}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText>{item.label}</ListItemText>
                  {item.subItems ? (
                    openReviews ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )
                  ) : null}
                </MenuItem>
                {item.subItems && (
                  <Collapse in={openReviews} timeout="auto" unmountOnExit>
                    <MenuList>
                      {item.subItems.map((subItem, subIndex) => (
                        <MenuItem
                          component={NavLink}
                          to={subItem.path}
                          key={subIndex}
                        >
                          <ListItemIcon>{subItem.icon}</ListItemIcon>
                          <ListItemText>{subItem.label}</ListItemText>
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Collapse>
                )}
              </Fragment>
            );
          })}
        </MenuList>
        <ButtonComponent
          variant="text"
          color="primary"
          text="Sign Out"
          onClick={handleLogout}
          align="center"
          startIcon={<LogoutIcon />}
          className={classes.signoutButton}
        />
      </Drawer>
    </Fragment>
  );
};

export default DrawerComponent;
