import { Typography } from '@mui/material';
import React, { Fragment } from 'react';

const TypoGraphyComponent = ({ text, ...otherProps }) => {
  return (
    <Fragment>
      <Typography gutterBottom={false} {...otherProps}>
        {text}
      </Typography>
    </Fragment>
  );
};

export default TypoGraphyComponent;
