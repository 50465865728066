import { endpoints } from "../config/apiConfig";
import axios from "axios";

function logError(error) {
  const message = `admin portal error, ${error}`;
  return new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.logger.logError}?error=${message}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export default {
  logError,
};
