import { Button } from "@mui/material";
import ChipComponent from "../../atoms/chip";
import Moment from "moment";

export const getColumns = (onDelete) => [
  {
    field: "id",
    headerName: "id",
    flex: 1,
  },
  {
    field: "productId",
    headerName: "Product Id",
    flex: 1,
  },
  {
    field: "reviewDate",
    headerName: "Review Date",
    flex: 1,
    renderCell: (params) => (
      <span>{Moment(params.value).format("YYYY-MM-DD")}</span>
    ),
  },
  {
    field: "starRating",
    headerName: "Star Rating",
    flex: 1,
  },
  {
    field: "isReviewedByLister",
    headerName: "Is Reviewed By Lister",
    flex: 1,
    renderCell: (params) => (
      <ChipComponent
        color={params.value ? "primary" : "secondary"}
        label={params.value ? "Yes" : "No"}
      />
    ),
  },
  {
    field: "isReviewedByRenter",
    headerName: "Is Reviewed By Renter",
    flex: 1,
    renderCell: (params) => (
      <ChipComponent
        color={params.value ? "primary" : "secondary"}
        label={params.value ? "Yes" : "No"}
      />
    ),
  },
  {
    field: "isManualReview",
    headerName: "Is Manual Review",
    flex: 1,
    renderCell: (params) => (
      <ChipComponent
        color={params.value ? "primary" : "secondary"}
        label={params.value ? "Yes" : "No"}
      />
    ),
  },
  {
    field: "reviewerUsername",
    headerName: "Reviewer Username",
    flex: 1,
  },
  {
    field: "comment",
    headerName: "Comment",
    flex: 1,
  },
  {
    field: "actions",
    headerName: "Actions",
    flex: 1,
    renderCell: (params) => {
      return (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            onDelete(params.row.id);
          }}
          variant="contained"
        >
          Delete
        </Button>
      );
    },
  },
];
