import { useState } from "react";
import { 
  Paper,
  Box,
  Button
} from "@mui/material";
import TypoGraphyComponent from "../../atoms/typography";
import CollectionsGrid from "./CollectionsGrid";
import { useNavigate } from "react-router-dom";

const Collections = () => {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  return (
    <>
      <Box pt={3} component={Paper} p={2} className="collctionsWrapper">
        <TypoGraphyComponent
          gutterBottom="gutterBottom"
          text="Collections"
          variant="h5"
          color="primary"
        />

        <Box display="flex" justifyContent="flex-end" pb={2} className="headerBtn">
          <Button
            variant="contained"
            color="primary"
           onClick={() => navigate("/create-collections")}
           
          >
            Create Collection
          </Button>
        </Box>
      </Box>
      <Box pt={3} component={Paper} p={2}>
        <CollectionsGrid />
      </Box>

     
    </>
  );
};

export default Collections;
