import ChipComponent from "../../atoms/chip";
import Moment from "moment";
export const getColumns = () => [
  {
    field: "purchaseDate",
    headerName: "Purchase Date",
    width: 150,
    renderCell: (cellValues) => {
      return (
        <ChipComponent
          label={Moment(cellValues.row.purchaseDate).format("DD-MM-YYYY")}
        />
      );
    },
    sortable: true,
  },
  {
    field: "giftCardCode",
    headerName: "Gift Card Code",
    width: 150,
    sortable: false,
  },
  {
    field: "giftCardAmount",
    headerName: "Gift Card Amount",
    width: 100,
    sortable: false,
  },
  {
    field: "buyerName",
    headerName: "Buyer Name",
    width: 200,
    sortable: false,
  },
  {
    field: "buyerEmail",
    headerName: "Buyer Email",
    width: 250,
    sortable: false,
  },
  {
    field: "recipientName",
    headerName: "Recipient Name",
    width: 200,
    sortable: false,
  },
  {
    field: "recipientEmail",
    headerName: "Recipient Email",
    width: 250,
    sortable: false,
  },
  {
    field: "reminderDate",
    headerName: "Reminder Date",
    width: 150,
    sortable: false,
    renderCell: (cellValues) => {
      return (
        <ChipComponent
          label={Moment(cellValues.row.reminderDate).format("DD-MM-YYYY")}
        />
      );
    },
  },
  {
    field: "isReminderSent",
    headerName: "Is Reminder Sent",
    width: 100,
    sortable: false,
  },
  {
    field: "personalizedMessage",
    headerName: "Personalized Message",
    width: 250,
    sortable: false,
  },
  {
    field: "remainingBalance",
    headerName: "Remaining Balance",
    width: 100,
    sortable: false,
  },
  {
    field: "paymentIntentId",
    headerName: "Payment Intent Id",
    width: 250,
    sortable: false,
  },
];
