import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

export default function ConfirmationDialog({
  title,
  message,
  acceptTitle,
  onAccept,
  rejectTitle,
  onReject,
  hideRejectOption,
  open,
}) {
  const [showDialog, setShowDialog] = React.useState(open);

  useEffect(() => {
    setShowDialog(open);
  }, [open]);

  const handleReject = () => {
    setShowDialog(!showDialog);
    onReject();
  };

  const handleAccept = () => {
    setShowDialog(!showDialog);
    onAccept();
  };

  return (
    <Box>
      <Dialog
        open={open}
        PaperComponent={PaperComponent}
        scroll="paper"
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          {Array.isArray(message) ? (
            message.map((item, index) => (
              <DialogContentText key={index}>{item}</DialogContentText>
            ))
          ) : (
            <DialogContentText>{message}</DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="appGray" onClick={handleReject}>
            {rejectTitle}
          </Button>
          <Button onClick={handleAccept}>{acceptTitle}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
