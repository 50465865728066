import { RNS3 } from 'react-native-aws3';
import axios from 'axios';
import { endpoints } from '../config/apiConfig';

const { baseUrl } = endpoints;

const uploadImageToS3 = async (image, keys) => {
  let options = {};
  options = {
    keyPrefix: keys.keyFolder,
    bucket: keys.bucketName,
    region: keys.awsRegion,
    accessKey: keys.awsAccessKeyID,
    secretKey: keys.awsSecretAccessKey,
    successActionStatus: 201,
  };

  return new Promise((resolve, reject) => {
    RNS3.put(image, options)
      .then((res) => {
        if (res.status === 201) {
          const { postResponse } = res.body;
          resolve({
            src: postResponse.location,
          });
        } else {
          console.log('upload image response : ', res);
          reject(res);
        }
      })
      .catch((err) => {
        console.log('error uploading to s3', err);
        reject(err);
      });
  });
};

// get s3Bucket keys from api
const getS3Keys = async (accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}${endpoints.keys.S3Keys}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => resolve(response))
      .catch((error) => {
        console.log('error in getS3Keys', error.response);
        reject(error.response);
      });
  });
};

const getCarouselData = async (accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${endpoints.carousel.getCarouselData}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => resolve(response))
      .catch((error) => {
        console.log('error in getS3Keys', error.response);
        reject(error.response);
      });
  });
};

const uploadAsset = async (payload, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.carousel.addCarouselData}`, payload, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const deleteAsset = async (payload, accessToken) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${endpoints.carousel.deleteCarouselData}`, {
        data: payload,
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const carouselApi = {
  uploadImageToS3,
  getS3Keys,
  getCarouselData,
  uploadAsset,
  deleteAsset,
};

export default carouselApi;
