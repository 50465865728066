import { React, useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Grid, Box } from '@mui/material';
import TypoGraphyComponent from '../../atoms/typography';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { endpoints } from '../../../config/apiConfig';
import logService from '../../../services/logService';
import { useStyles } from './styles';

import CarouselItem from '../../molecules/carouselItem';
import carouselApi from './../../../api/carouselApi';
import ButtonComponent from '../../atoms/button';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { TOTAL_ASSETS, DEFAULT_ID } from '../../../constants/carouselConstants';
import CircularProgress from '@mui/material/CircularProgress';
import ConfirmationDialog from '../../ConfirmationDialog/index';

const appendEmptyItems = (dataItems) => {
  let updatedData = [];
  // empty objects to define the array of length X
  for (let i = 0; i < TOTAL_ASSETS; i++) {
    updatedData.push({});
  }
  // replace the items on the positions
  dataItems.forEach((item) => {
    updatedData[item.position] = item;
  });

  return updatedData;
};

const CarouselScreen = () => {
  const classes = useStyles();
  let accessToken = localStorage.getItem('_token');

  const [isLoading, setIsLoading] = useState(false);
  const [carouselData, setCarouselData] = useState([]);
  const [s3Keys, setS3Keys] = useState({});
  const [dirtyItems, setDirtyItems] = useState([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [fileToDelete, setFileToDelete] = useState({});

  useLayoutEffect(() => {
    fetchCarouselData();
  }, []);

  useEffect(() => {
    carouselApi
      .getS3Keys(accessToken)
      .then((keysResult) => {
        if (keysResult && keysResult.status === 200 && keysResult.data) {
          const keys = keysResult.data.data;
          setS3Keys(keys);
        }
      })
      .catch((error) => {
        console.log('Unable to get s3 keys', error);
        logService.logError(
          `function: getS3Keys in Edit Listing, client error msg: ${error.status}`
        );
      });
  }, []);

  const fetchCarouselData = async (params) => {
    setIsLoading(true);
    try {
      const response = await carouselApi.getCarouselData(accessToken);
      console.log('carousel response ', response);
      if (response.status === 200 && response.data.success) {
        let { data } = response.data;
        let updatedData = appendEmptyItems(data);
        setCarouselData(updatedData);
      }
    } catch (error) {
      logService.logError(
        `file: reservations> index.js, function: fetchCarouselData, message: ${error.message}`
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileSelect = (fileObject) => {
    let updatedList = [];
    carouselData.forEach((item, index) => {
      if (index === fileObject.position) {
        updatedList.push(fileObject);
      } else {
        updatedList.push(item);
      }
      const updatedDirtyList = [...dirtyItems, fileObject.position];
      setDirtyItems(updatedDirtyList);
    });
    setCarouselData(updatedList);
  };

  const handleLinkAddressChange = (linkAddress, position) => {
    const updatedData = carouselData.map((item) =>
      item.position === position ? { ...item, webAddress: linkAddress } : item
    );
    setCarouselData(updatedData);
    const updatedDirtyList = [...dirtyItems, position];
    setDirtyItems(updatedDirtyList);
  };

  const handleFileUpload = async () => {
    try {
      setIsLoading(true);
      let filesToUpload = carouselData.filter((item) => {
        return dirtyItems.includes(item.position);
      });

      let newlyAddedFilesPromises = []; // these files need to be uploaded on s3
      let newlyAddedFiles = []; // to be able to refer them later
      let alreadyUploadedFiles = []; // these files are already uploaded on s3

      filesToUpload.forEach((fileObject, i) => {
        if (fileObject.file) {
          newlyAddedFiles.push(fileObject);
          newlyAddedFilesPromises.push(
            carouselApi.uploadImageToS3(fileObject.file, s3Keys)
          );
        } else {
          alreadyUploadedFiles.push(fileObject);
        }
      });

      if (newlyAddedFiles.length > 0) {
        const uploadedFiles = await Promise.all(newlyAddedFilesPromises);

        // Now remove files objects from data and replace file src
        // to the s3 url to make them ready to be uploaded to the server
        newlyAddedFiles = newlyAddedFiles.map((fileObject, index) => {
          const { file, ...otherFileFields } = fileObject;
          return { ...otherFileFields, url: uploadedFiles[index]['src'] };
        });
      }

      filesToUpload = [...newlyAddedFiles, ...alreadyUploadedFiles];

      uploadFiles(filesToUpload);
    } catch (error) {
      logService.logError('error uploading image to s3', error);
      setIsLoading(false);
    }
  };

  const uploadFiles = (files) => {
    carouselApi
      .uploadAsset(files, accessToken)
      .then((response) => {
        console.log('asset upload api response: ', response);
        if (response.status === 200 && response.data.success) {
          fetchCarouselData();
          setDirtyItems([]);
        }
        setIsLoading(false);
        toast.success('Successfully uploaded!');
      })
      .catch((error) => {
        logService.logError('error uploading asset', error);
        setIsLoading(false);
        toast.success('Failed to upload!');
      });
  };

  const handleDeleteClick = (fileObject) => {
    setFileToDelete(fileObject);
    setShowDeleteDialog(true);
  };

  const handleFileDelete = () => {
    setShowDeleteDialog(false);
    setIsLoading(true);

    let fileObject = fileToDelete;
    const { id, position } = fileObject;
    if (id !== DEFAULT_ID) {
      // file has been uploaded on server
      carouselApi
        .deleteAsset({ id }, accessToken)
        .then((response) => {
          console.log('asset delete api response: ', response);
          if (response.status === 200 && response.data.success) {
            const updatedData = carouselData.map((item) =>
              item.id === id ? {} : item
            );
            setCarouselData(updatedData);
          }
          setIsLoading(false);
          toast.success('Successfully deleted!');
          setFileToDelete({});
        })
        .catch((error) => {
          logService.logError('error deleting file', error);
          setIsLoading(false);
          toast.success('Failed to delete!');
          setFileToDelete({});
        });
    } else {
      // file has not been uploaded yet
      // need to delete in local state only
      const updatedData = carouselData.map((item) =>
        item.position === position ? {} : item
      );
      setCarouselData(updatedData);

      // also remove from dirtyList so that it's up to date
      if (dirtyItems.includes(position)) {
        let updatedDirtyList = dirtyItems.filter((item) => item !== position);
        setDirtyItems(updatedDirtyList);
      }
      setIsLoading(false);
      setFileToDelete({});
    }
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      className={classes.container}
    >
      <Toaster
        toastOptions={{
          className: '',
          style: {
            color: '#FFB3C3',
            backgroundColor: 'rgba(40, 0, 0, 0.87)',
            fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
          },
        }}
      />
      <Box className={classes.header}>
        <TypoGraphyComponent text="Carousel" variant="h5" color="primary" />
        <Box className={classes.headerOptions}>
          <Box className={classes.headerOptionsLeft}></Box>
          <Box className={classes.headerFreeSpace}></Box>
          <Box className={classes.headerOptionsRight}>
            {dirtyItems.length > 0 ? (
              <Box className={classes.delete}>
                <ButtonComponent
                  style={{ padding: 0 }}
                  variant="text"
                  color="primary"
                  text="Upload"
                  onClick={handleFileUpload}
                  align="center"
                  startIcon={<CloudUploadOutlinedIcon />}
                  disabled={isLoading}
                />
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
      <Box className={classes.carouselContainer}>
        {isLoading ? (
          <Box className={classes.loderContainer}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {carouselData.map((item, index) => (
              <CarouselItem
                key={index}
                data={item}
                onFileSelect={handleFileSelect}
                onFileUpload={handleFileUpload}
                onFileDelete={handleDeleteClick}
                onLinkAddressChange={handleLinkAddressChange}
                position={index}
              />
            ))}
          </>
        )}
      </Box>
      <ConfirmationDialog
        open={showDeleteDialog}
        title="Asset is going to be deleted!"
        message={[
          'This asset will be permanantly deleted. You can always add a new one.',
          'Are you sure you want to continue?',
        ]}
        acceptTitle="DELETE"
        onAccept={handleFileDelete}
        rejectTitle="CANCEL"
        onReject={() => setShowDeleteDialog(false)}
      />
    </Grid>
  );
};

export default CarouselScreen;
