import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    boxSizing: 'border-box',
    height: '100%',
    width: '100%',
    flex: 1,
  },
  loderContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: 15,
    border: '1px solid lightgray',
    boxShadow: '0px 0px 2px 0px lightgray',
    flexDirection: 'row',
  },
  headerOptions: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 30,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  headerOptionsLeft: {
    display: 'flex',
    flexDirection: 'row',
  },
  headerFreeSpace: {
    flex: 1,
  },
  headerOptionsRight: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  carouselContainer: {
    flex: 1,
    padding: 15,
    marginLeft: 20,
    overflow: 'auto',
  },
  carouselItem: {
    width: '80%',
    minHeight: 130,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 5,
    paddingBottom: 5,
    borderBottom: '1px solid lightgray',
    display: 'flex',
    alignItems: 'center',
  },
  carouselItemLeft: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  carouselItemFreeSpace: {
    flex: 1,
  },
  carouselItemRight: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },
  iconButton: {
    marginLeft: 10,
    cursor: 'pointer',
  },
  fileName: {
    marginLeft: 20,
  },
  mediaComponent: {
    borderRadius: 3,
  },
});
