/* eslint-disable react-hooks/exhaustive-deps */
//packages block
import { useEffect, useState } from 'react';
import { Table, TableBody, Paper, TableContainer, Box, TableHead, TableRow, TableCell, Button, IconButton, Grid, TextField, Switch, Chip, TablePagination } from "@mui/material";
import { Delete, Edit, Search } from '@mui/icons-material';
import axios from 'axios';
import moment from 'moment';
//components block
import TableLoader from "../../atoms/loader/TableLoader";
import DataNotFound from "../../atoms/dataNotFound";
import CouponModal from "../../molecules/couponDialogue";
import ConfirmationModal from '../../atoms/alert/ConfirmationDeleteAlert';
import Alert from '../../atoms/alert';
import TypoGraphyComponent from "../../atoms/typography";
//api config
import { endpoints } from '../../../config/apiConfig';

export const toTitleCase = (toTransform) => {
  return toTransform.replace(/\b([a-z])/g, function (_, initial) {
    return initial.toUpperCase();
  });
};

export const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

const Coupon = () => {
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false)
  const [openActiveDeactivateBar, setOpenActiveDeactivateBar] = useState(false)

  const [hasMore, setHasMore] = useState(false);
  const [totalPages, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);

  const [couponCode, setCouponCode] = useState("");

  const [coupons, setCoupons] = useState([]);
  const [productCategories, setProductCategories] = useState([]);

  const [selectedCoupon, setSelectedCoupon] = useState(null);

  const fetchCategories = async () => {
    try {
      const { data: { data } } = await axios.get(`${endpoints.products.getProductCategories}`, {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("_token")}`
        }
      });

      setProductCategories(data)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchCoupons = async () => {
    try {
      setLoading(true);
      const { data: { total, success, data: { data, hasMore } } } = await axios.get(`${endpoints.coupons.getCoupons}?limit=10&current=${(currentPage + 1)}&Search=${couponCode}`, {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("_token")}`
        }
      });

      success && setCoupons(data)
      setTotalPage(total)
      setHasMore(hasMore)
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error)
    }
  }

  const handleEdit = (coupon) => {
    setSelectedCoupon(coupon)
    setOpen(true)
  }

  const handleDelete = (coupon) => {
    setSelectedCoupon(coupon)
    setOpenDelete(true)
  }

  const handlePageChange = (_, value) => setCurrentPage(value);

  const handleDeleteSubmission = async () => {
    try {
      setDeleteLoading(true);
      const updatedCoupons = [];
      const { data: { message } } = await axios.delete(`${endpoints.coupons.createOrUpdateCoupon}/${selectedCoupon?.id}`, {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("_token")}`
        }
      });
      for (let coupon of coupons) {
        const { id } = coupon
        if (parseInt(id) !== parseInt(selectedCoupon?.id)) {
          updatedCoupons.push(coupon)
        }
      }

      setCoupons(updatedCoupons)

      setSelectedCoupon(null)
      setOpenDelete(false)
      setDeleteLoading(false);
      message && Alert.success(message)
    } catch (error) {
      setDeleteLoading(false);
    }
  };

  useEffect(() => {
    fetchCoupons()
  }, [currentPage, currentPage])

  useEffect(() => {
    if (!openDelete) {
      setSelectedCoupon(null)
    }
  }, [openDelete]);

  useEffect(() => {
    fetchCategories()
  }, []);

  const searchRequest = () => {
    setCurrentPage(0)
    fetchCoupons();
  };

  const handleActiveDeactivate = (coupon) => {
    setSelectedCoupon(coupon);
    setOpenActiveDeactivateBar(true)
  }

  const handleActiveDeactivateSubmission = async () => {
    try {
      // call to api
      const updatedCoupons = [];
      const { data: { message } } = await axios.put(`${endpoints.coupons.activeDeactivateCoupon}/${selectedCoupon?.id}/${!(selectedCoupon?.isActive)}`, {}, {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("_token")}`
        }
      });

      for (let coupon of coupons) {
        const { id, isActive = false } = coupon
        if (parseInt(id) === parseInt(selectedCoupon?.id)) {
          updatedCoupons.push({ ...coupon, isActive: !isActive })
        } else {
          updatedCoupons.push(coupon);
        }
      }

      setCoupons(updatedCoupons)
      Alert.success(message)
      setSelectedCoupon(null);
      setDeleteLoading(false);
      setOpenActiveDeactivateBar(false)
    } catch (err) {
      setDeleteLoading(false);
    }

  }

  return (
    <Box>
      <Box pt={3} component={Paper} p={2}>
        <TypoGraphyComponent
          gutterBottom="gutterBottom"
          text="Discounts"
          variant="h5"
          color="primary"
        />

        <Box display="flex" justifyContent="flex-end" pb={2}>
          <Button variant='contained' color="primary" onClick={() => setOpen(true)}>
            Create Discount
          </Button>
        </Box>

        <Grid container spacing={2}>
          <Grid item sm={12} lg={4} md={4} xs={12}>
            <TextField
              fullWidth
              margin="none"
              name="Search"
              variant="outlined"
              value={couponCode}
              label="Search"
              placeholder="Search By Coupon Code or Name"
              onKeyPress={({ key }) => key === "Enter" && searchRequest()}
              onChange={({ target: { value } }) => setCouponCode(value)}
              InputProps={{
                endAdornment:
                  <IconButton color="default" onClick={() => { searchRequest() }}>
                    <Search color="inherit" />
                  </IconButton>
              }}
            />
          </Grid>
        </Grid>

        <Box>
          <TableContainer component={Paper} sx={{ height: '60vh', overflow: 'auto' }}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Code</TableCell>
                  <TableCell>Product Categories</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Discount</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>New Customer Only?</TableCell>
                  <TableCell>Used Count</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>


              {!loading && (
                <TableBody>
                  {coupons.map((coupon, index) => {
                    const { name, code, category, type, startDate, endDate, discountValue, usedCount, isActive = false, categoryVariantNames, isNewCustomer } = coupon;
                    const couponCategory = parseInt(category) === 1 ? "One Time" : "Multiple Time";
                    const couponType = parseInt(type) === 1 ? "Fixed Amount" : "Percentage";
                    const discount = couponType === "Percentage" ? `${discountValue}%` : discountValue || "N/A"
                    const newCustomer = isNewCustomer ? "Yes" : "No";
                    return (
                      <TableRow key={`${name - index}`}>
                        <TableCell>{toTitleCase(name)}</TableCell>
                        <TableCell>{code}</TableCell>
                        <TableCell>
                          <Box display="flex" alignItems="center" gap="5px">
                            {categoryVariantNames.length ?
                              categoryVariantNames.map((item) => (<Chip label={item} />))
                              : "N/A"}
                          </Box>
                        </TableCell>
                        <TableCell>{couponCategory}</TableCell>
                        <TableCell>{couponType}</TableCell>
                        <TableCell>{discount}</TableCell>
                        <TableCell>{startDate ? formatDate(moment(startDate).local()) : "N/A"}</TableCell>
                        <TableCell>{endDate ? formatDate(moment(endDate).local()) : "N/A"}</TableCell>
                        <TableCell>{newCustomer}</TableCell>
                        <TableCell> {usedCount}</TableCell>
                        <TableCell>
                          <Box display='flex' alignItems="center">
                            <Switch
                              checked={isActive}
                              onChange={() => handleActiveDeactivate(coupon)}
                            />

                            <IconButton color='success' onClick={() => handleEdit(coupon)}>
                              <Edit />
                            </IconButton>

                            <IconButton color='error' onClick={() => handleDelete(coupon)}>
                              <Delete />
                            </IconButton>
                          </Box>
                        </TableCell>

                      </TableRow>
                    )
                  })}

                </TableBody>
              )}
            </Table>

            {loading &&
              <TableLoader numberOfRows={10} numberOfColumns={9} />
            }

            {!loading && !coupons.length &&
              <DataNotFound />
            }
          </TableContainer>
        </Box>

        {(hasMore || currentPage >= 1) &&
          <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
            <TablePagination
              sx={{
                "& .MuiInputBase-root": {
                  display: "none"
                },

                "& .MuiTablePagination-selectLabel": {
                  display: "none"
                }
              }}

              component="div"
              count={totalPages}
              page={currentPage}
              onPageChange={handlePageChange}
              rowsPerPage={10}
            />
          </Box>
        }

      </Box>

      <CouponModal
        open={open}
        setOpen={setOpen}
        coupons={coupons}
        setCoupons={setCoupons}
        selectedCoupon={selectedCoupon}
        setSelectedCoupon={setSelectedCoupon}
        productCategories={productCategories}
      />

      <ConfirmationModal
        isOpen={openDelete}
        setOpen={setOpenDelete}
        title="Delete Discount Code"
        description={`Do you really want to delete this discount code?`}
        isLoading={deleteLoading}
        handleDelete={handleDeleteSubmission}
        actionText="Confirm"
      />

      <ConfirmationModal
        isOpen={openActiveDeactivateBar}
        setOpen={setOpenActiveDeactivateBar}
        title={`${!selectedCoupon?.isActive ? "Activate" : "Deactivate"} Discount Code`}
        description={`Do you really want to ${!selectedCoupon?.isActive ? "activate" : "deactivate"} this discount code?`}
        isLoading={deleteLoading}
        handleDelete={handleActiveDeactivateSubmission}
        actionText="Confirm"
      />
    </Box>
  )
}

export default Coupon
