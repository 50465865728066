import { ThemeProvider } from "@emotion/react";
import { Fragment } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { SnackbarProvider } from "notistack";

import { SnackbarUtilsConfigurator, CloseButton } from "./components/atoms/alert";
import AuthLayoutComponent from "./components/templates/auth";
import DashboardComponent from "./components/templates/main";
import ProtectedRoute from "./ProtectedRoute";
import { theme } from "./theme";
import "./App.css"

function App() {
  return (
    <Fragment>
      <SnackbarProvider maxSnack={5} autoHideDuration={3000} action={key => <CloseButton id={key} />}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}>
        <SnackbarUtilsConfigurator />
      </SnackbarProvider>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/*"
              element={
                <ProtectedRoute redirectTo="/">
                  <DashboardComponent />
                </ProtectedRoute>
              }
            />
            <Route path="/" element={<AuthLayoutComponent />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </Fragment>
  );
}

export default App;