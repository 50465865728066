import axios from 'axios';
import { endpoints } from "../../config/apiConfig";
import { PRODUCT_ACTIONS } from '../actionTypes';
import toast, { Toaster } from 'react-hot-toast';
import logService from '../../services/logService';

export const getProductDetailById = (productid, userid) => (dispatch) => {
    return axios
        .get(`${endpoints.productActions.getProductDetailById}?productId=${productid}&userId=${userid}`,
            { headers: { 'Content-Type': 'application/json; charset=utf-8' } },
        )
        .then((response) => {
            const baseModel = response.data;
            if (baseModel.success) {
                {
                    const { data } = baseModel;
                    dispatch({
                        type: PRODUCT_ACTIONS.SET_PDP_DATA,
                        payload: data,
                    });
                }
            }
            else {
                toast.error('Something went wrong.');
            }
            return baseModel;
        })
        .catch((error) => {
            logService.logError(
                ` function: ReturnAddressActions, message: ${error.message}`
            );
            return {
                success: false,
            };
        })
}
