// packages block
import React from "react";
import { Typography, Box } from "@mui/material";
import { FolderOpen } from "@mui/icons-material";

const DataNotFound = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box color="#E3EEFA" fontSize={120} display="flex" alignItems="center">
        <FolderOpen fontSize="inherit" color="inherit" />
      </Box>

      <Box>
        <Typography component="h5" variant="h5" color="primary">
          No data found
        </Typography>
      </Box>
    </Box>
  );
};

export default DataNotFound;
