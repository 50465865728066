import React, { useEffect, useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import { endpoints } from "../../../config/apiConfig";
import axios from "axios";
import TypoGraphyComponent from "../../atoms/typography";
import { useStyles } from "./styles";
import { getColumns } from "./columnsConfig";
import { DataGrid } from "@mui/x-data-grid";
import * as utilityService from "../../../services/utilityService";
import toast, { Toaster } from "react-hot-toast";
import AddUpdateTopListerModal from "../../molecules/AddUpdateTopListerModal/AddUpdateTopListerModal";

function TopListerScreen(props) {
  const classes = useStyles();
  const [topRatedListers, setTopRatedListers] = useState([]);
  const [listers, setListers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [isAddUpdateModalOpen, setIsAddUpdateModalOpen] = useState(false);
  const [selectedLister, setSelectedLister] = useState(null);
  const accessToken = localStorage.getItem("_token");

  const fetchListers = async (params) => {
    setIsLoading(true);
    const response = await axios.get(endpoints.reviews.topListers, {
      headers: {
        "Content-Type": "application/json-patch+json",
        Authorization: `Bearer ${accessToken}`,
      },
      params,
    });
    if (response.data.success) {
      const mappedData = utilityService.mapTopListerData(
        response.data.data.data
      );
      setTopRatedListers(mappedData);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setTopRatedListers([]);
    }
  };

  const fetchAllUsers = async (params) => {
    const response = await axios.get(endpoints.reviews.topListers, {
      headers: {
        "Content-Type": "application/json-patch+json",
        Authorization: `Bearer ${accessToken}`,
      },
      params,
    });
    if (response.data.success) {
      const mappedData = utilityService.mapTopListerData(
        response.data.data.data
      );
      setListers(mappedData);
    } else {
      setListers([]);
    }
  };

  useEffect(() => {
    fetchListers({
      IsTopRatedLister: true,
    });
    fetchAllUsers({
      IsTopRatedLister: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const assignTopLister = async (lister) => {
    setSelectedLister(lister);
    setIsAddUpdateModalOpen(true);
  };
  const columns = getColumns(assignTopLister);

  const handleToggleTopRated = async (selectedLister, isTopRated) => {
    if (selectedLister) {
      setUpdateLoading(true);
      const response = await axios.post(
        endpoints.reviews.updateTopLister,
        {
          userId: selectedLister.userId,
          isTopRatedLister: isTopRated,
        },
        {
          headers: {
            "Content-Type": "application/json-patch+json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.data.success) {
        toast.success("Lister status updated successfully");
        fetchListers({
          IsTopRatedLister: true,
        });
        fetchAllUsers({
          IsTopRatedLister: false,
        });
        setUpdateLoading(false);
        setIsAddUpdateModalOpen(false);
        setSelectedLister(null);
      } else {
        setUpdateLoading(false);
      }
    }
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      className={classes.container}
    >
      <Toaster
        toastOptions={{
          className: "",
          style: {
            color: "#FFB3C3",
            backgroundColor: "rgba(40, 0, 0, 0.87)",
            fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
          },
        }}
      />
      <Box className={classes.header}>
        <TypoGraphyComponent
          text="Top Rated Listers"
          variant="h5"
          color="primary"
        />
      </Box>
      <Box className={classes.addTopListerButton}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => assignTopLister(null)}
        >
          Add Top Lister
        </Button>
      </Box>
      <Box className={classes.tableContainer}>
        <DataGrid
          rows={topRatedListers}
          columns={columns}
          disableColumnMenu
          disableSelectionOnClick
          loading={isLoading}
          paginationMode="server"
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              textOverflow: "clip",
              whiteSpace: "break-spaces",
              lineHeight: 1,
            },
          }}
        />
      </Box>
      <AddUpdateTopListerModal
        open={isAddUpdateModalOpen}
        onClose={() => {
          setIsAddUpdateModalOpen(false);
          setSelectedLister(null);
        }}
        users={listers}
        onUpdate={handleToggleTopRated}
        updateLister={selectedLister}
        updateLoading={updateLoading}
      />
    </Grid>
  );
}

export default TopListerScreen;
