export const BOOKING_STATUS = {
  REQUESTED: "requested",
  NEW_REQUEST: "newRequest",
  BOOKED: "booked",
  TO_POST: "toPost",
  LIVE: "live",
  COMPLETED: "completed",
  DECLINED: "declined",
};

export const PAGE_LIMIT = 20;

export const PAYMENT_STATUS = {
  Pending: 1,
  Paid: 2,
  Hold: 3,
  Canceled: 4,
};
