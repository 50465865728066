import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    boxSizing: 'border-box',
    height: '100%',
    width: '100%',
    flex: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: 15,
    border: '1px solid lightgray',
    boxShadow: '0px 0px 2px 0px lightgray',
    flexDirection: 'row',
  },
  tableContainer: {
    flex: 1,
    padding: 15,
    paddingBottom: 223,
    height: '100%',
    overflow: 'auto',
  },
  loderContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadMoreContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadMoreCircularProgressContainer: {
    width: 25,
    height: 25,
    padding: 5,
  },
  indexLabel: {
    width: 100,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  // headerOptions: {
  //   flex: 1,
  //   display: 'flex',
  //   flexDirection: 'row',
  //   paddingLeft: 30,
  //   alignItems: 'center',
  //   justifyContent: 'flex-start',
  // },
  headerOptionsLeft: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerFreeSpace: {
    flex: 1,
  },
  headerOptionsRight: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },
  moveButtonContainer: {
    display: 'inline',
    minWidth: 100,
  },
  positionInputContainer: {
    marginLeft: 10,
  },
  creteCollctionsWrapper: {
    display: "flex",
    flexDirection: "column"
  },
  formRow: {
    display: "block",
    marginBottom: "18px !important"
  },
  backLink: {
    textDecoration: "none",
    marginBottom: 30,
    display: "flex",
    alignItems: "center",
    fontFamily: "Arial",
    color: "#000"
  },
  addCollection: {
    marginTop: "25px !important",
    display: "inline-block !important",
    color: "#000 !important",
    textTransform: "capitalize  !important",
    fontWeight: "600 !important"
  },
  addCollectionFooter: {
    marginTop: "25px !important",
    display: "inline-block !important",
    color: "#000 !important",
    textTransform: "capitalize  !important",
    fontWeight: "600 !important",
    padding: "6px 44px  !important",
  },
  addCollectionFooterWrapper: {
    display: "flex",
    justifyContent: "flex-end"
  }

});
