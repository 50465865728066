import ChipComponent from "../../atoms/chip";
import Moment from "moment";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";

function CustomHeader({ onCheckboxChange, checked }) {
  return (
    <div>
      Is Purchased
      <Checkbox
        checked={checked}
        onChange={(e) => onCheckboxChange(e.target.checked)}
      />
    </div>
  );
}

export const getColumns = (
  handleClick,
  showAddress,
  handleIsPurchasedChange,
  isPurchased,
  setIsPurchased
) => [
  {
    field: "paymentStatus",
    headerName: "Payment Action",
    width: 100,
    sortable: false,
    renderCell: (cellValues) => {
      const disabled =
        cellValues.row.isPaidToConnectedAccount === "NO" ||
        cellValues.row.paymentStatusName === "Canceled" ||
        cellValues.row.paymentStatusName === "Paid"
          ? true
          : false;
      return (
        <IconButton
          aria-label="more"
          aria-controls="menu"
          aria-haspopup="true"
          disableFocusRipple={disabled}
          disableRipple={disabled}
          disableTouchRipple={disabled}
          disabled={disabled}
          onClick={disabled ? null : (event) => handleClick(cellValues, event)}
        >
          {/* make it thick */}
          <MoreVertIcon
            style={{
              fontSize: "2rem",
              color: disabled ? "grey" : "black",
            }}
          />
        </IconButton>
      );
    },
  },

  {
    field: "createdOn",
    headerName: "Created on",
    width: 120,
    sortable: false,
    renderCell: (params) => {
      return Moment(params.value).format("MMM Do YYYY");
    },
  },
  {
    field: "isPurchased",
    headerName: "Is Purchased",
    width: 150,
    sortable: false,
    renderHeader: (params) => (
      <CustomHeader
        checked={isPurchased}
        onCheckboxChange={(value) => {
          setIsPurchased(value);
          handleIsPurchasedChange(value);
        }}
      />
    ),
  },
  {
    field: "webReservation",
    headerName: "Web Reservation",
    width: 120,
    sortable: false,
  },
  {
    field: "startDate",
    headerName: "Start Date",
    width: 120,
    sortable: false,
    renderCell: (params) => {
      return params.value ? Moment(params.value).format("MMM Do YYYY"):"N/A";
    },
  },
  {
    field: "endDate",
    headerName: "End Date",
    width: 120,
    sortable: false,
    renderCell: (params) => {
      return params.value ? Moment(params.value).format("MMM Do YYYY"):"N/A";
    },
  },
  ...(isPurchased
    ? [
        {
          field: "resalePrice",
          headerName: "Purchased Price",
          width: 120,
          sortable: false,
        },
      ]
    : [
        {
          field: "price",
          headerName: "Rental Price",
          width: 100,
          sortable: false,
        },
      ]),
  {
    field: "totalWithoutDiscount",
    headerName: "Total for lister (Price + Postage)",
    width: 140,
    sortable: false,
    renderCell: (cellValues) => {
      return `£${cellValues.formattedValue}`;
    },
  },
  {
    field: "discountCode",
    headerName: "Discount Code",
    width: 120,
    sortable: false,
    renderCell: (cellValues) => {
      return `${cellValues.value}`;
    },
  },
  {
    field: "discountedAmount",
    headerName: "Discount Amount",
    width: 100,
    sortable: false,
    renderCell: (cellValues) => {
      return `£${cellValues.value}`;
    },
  },
  {
    field: "damageProtectionFee",
    headerName: "Damage Protection",
    width: 100,
    sortable: false,
    renderCell: (cellValues) => {
      return `£${cellValues.value}`;
    },
  },
  {
    field: "transactionFee",
    headerName: "User Fee(2.5%)",
    width: 100,
    sortable: false,
    renderCell: (cellValues) => {
      return `£${cellValues.formattedValue}`;
    },
  },
  {
    field: "totalAmount",
    headerName: "Total paid in Stripe",
    width: 100,
    sortable: false,
    renderCell: (cellValues) => {
      return `£${cellValues.formattedValue}`;
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 100,
    sortable: false,
    renderCell: (cellValues) => {
      return (
        <ChipComponent
          size="small"
          label={cellValues.formattedValue}
          color={
            cellValues.formattedValue === "declined" ? "primary" : "secondary"
          }
        />
      );
    },
  },
  {
    field: "isPaidToConnectedAccount",
    headerName: "Is Paid To Connected Account?",
    width: 100,
    sortable: false,
    renderCell: (cellValues) => {
      return (
        <ChipComponent
          size="small"
          label={cellValues.formattedValue}
          color={cellValues.formattedValue === "NO" ? "primary" : "secondary"}
        />
      );
    },
  },
  {
    field: "paymentStatusName",
    headerName: "Lister Payment Status",
    width: 100,
    sortable: false,
    align: "center",
    renderCell: (cellValues) => {
      let chipColor;
      if (cellValues.formattedValue === "Pending") {
        chipColor = { backgroundColor: "#F6BE00", color: "black" };
      } else if (cellValues.formattedValue === "Canceled") {
        chipColor = { backgroundColor: "#8b0000", color: "white" };
      } else if (cellValues.formattedValue === "Paid") {
        chipColor = { backgroundColor: "#046718", color: "white" };
      } else if (cellValues.formattedValue === "Hold") {
        chipColor = { backgroundColor: "#333", color: "white" };
      }

      return !cellValues.formattedValue ? (
        "N/A"
      ) : (
        <ChipComponent
          size="small"
          label={cellValues.formattedValue}
          style={chipColor}
        />
      );
    },
  },
  { field: "renter",  headerName: isPurchased ? "Buyer Name" : "Renter Name", width: 200, sortable: false },
  {
    field: "renterEmail",
    headerName: isPurchased ? "Buyer Email" : "Renter Email",
    width: 250,
    sortable: false,
  },
  { field: "lister", headerName: "Lister Name", width: 200, sortable: false },
  {
    field: "listerEmail",
    headerName: "Lister Email",
    width: 250,
    sortable: false,
  },
  {
    field: "product_id",
    headerName: "Product Id",
    width: 100,
    sortable: false,
  },
  {
    field: "title",
    headerName: "Product Title",
    width: 200,
    sortable: false,
  },
  { field: "size", headerName: "Product Size", width: 120, sortable: false },
  {
    field: "colour",
    headerName: "Product Color",
    width: 120,
    sortable: false,
  },
  {
    field: "retailPrice",
    headerName: "Retail Price",
    width: 120,
    sortable: false,
  },
  {
    field: "isPaymentDetailAdded",
    headerName: "Payment Status",
    width: 130,
    sortable: false,
    renderCell: (cellValues) => {
      return (
        <ChipComponent
          size="small"
          label={cellValues.formattedValue}
          color={cellValues.formattedValue === "Paid" ? "secondary" : "primary"}
        />
      );
    },
  },
  {
    field: "deliveryOptions",
    headerName: "Delivery Method",
    width: 300,
    sortable: false,
  },
  {
    field: "productId",
    headerName: "Return Address",
    width: 300,
    sortable: false,
    renderCell: (cellValues) => {
      return (
        <button
          class="btn btn-info btn-sm"
          onClick={() => showAddress(cellValues.row.id)}
        >
          Return Address{" "}
        </button>
      );
    },
  },
];
