// packages block
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export const ShowPassword = ({ passwordType, handleShowPassword }) => (
  <InputAdornment position="end">
    <IconButton onClick={handleShowPassword}>
      {passwordType === "password" ? <Visibility color="secondary" /> : <VisibilityOff color="secondary" />}
    </IconButton>
  </InputAdornment>
);
