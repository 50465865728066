import * as types from '../actionTypes';

const INITIAL_STATE = {
    pdpProduct: {},
};

export default (state, action) => {
    if (!state) state = INITIAL_STATE;
    switch (action.type) {

        case types.PRODUCT_ACTIONS.SET_PDP_DATA:
            {
                return {
                    ...state,
                    pdpProduct: action.payload
                };
            }

        default:
            return state;
    }
};

